<!-- TimeComparison.vue -->
<template>
  <div class="time-comparison-wrapper" role="region" aria-label="Time Comparison">
    <div class="star-field">
      <div class="layer" v-for="n in 3" :key="n"></div>
    </div>
    <div class="time-comparison bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-2xl text-white backdrop-filter backdrop-blur-md">
      <h2 class="text-3xl font-bold mb-6 text-center">
        <i class="fas fa-clock mr-3"></i> {{ t('timeAcrossGlobe') }}
      </h2>
      <div class="time-cards-container">
        <div class="time-card" role="timer" aria-label="Your Local Time">
          <h3 class="text-xl font-semibold mb-2">
            <i class="fas fa-map-pin mr-2"></i> {{ t('yourTime') }}
          </h3>
          <p class="text-3xl font-bold">
            {{ formatTimeWithoutSeconds(visitorTime) }}
            <span class="text-2xl font-normal animate-pulse">{{ formatSeconds(visitorTime) }}</span>
            {{ formatAmPm(visitorTime) }}
          </p>
        </div>
        <div class="time-card" role="timer" aria-label="My Time">
          <h3 class="text-xl font-semibold mb-2">
            <i class="fas fa-map-pin mr-2"></i> {{ t('myTime', { location: myLocation }) }}
          </h3>
          <p class="text-3xl font-bold">
            {{ formatTimeWithoutSeconds(myTime) }}
            <span class="text-2xl font-normal animate-pulse">{{ formatSeconds(myTime) }}</span>
            {{ formatAmPm(myTime) }}
          </p>
        </div>
      </div>
      <div class="mt-6 text-center">
        <p class="text-xl">{{ message }}</p>
        <p class="mt-2 text-lg text-gray-300">{{ suggestion }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeComparison',
  data() {
    return {
      visitorTime: new Date(),
      myTime: new Date(),
      timeDifference: 0,
      myTimezone: 'Europe/Berlin',
      myLocation: 'Berlin',
      message: '',
      suggestion: '',
      locale: 'en-US',
      translations: {
        'en-US': {
          timeAcrossGlobe: 'Time Across the Globe',
          yourTime: 'Your Time',
          myTime: 'My Time ({location})',
          inSync: "We're in sync across the globe!",
          ahead: "I'm {difference} hours ahead in the future.",
          behind: "I'm {difference} hours behind in the past.",
          dreaming: "I'm probably dreaming of code right now, but leave a message!",
          morningCoffee: "I'm probably sipping my morning coffee. I'll get back to you soon!",
          coding: "I'm likely deep in lines of code. I'll respond as soon as I can!",
          windingDown: "I'm probably winding down, but I'll check messages soon!"
        },
        // Add more languages here
      }
    }
  },
  methods: {
    formatTimeWithoutSeconds(date) {
      return date.toLocaleTimeString(this.locale, { hour: '2-digit', minute: '2-digit', hour12: true }).slice(0, -3);
    },
    formatSeconds(date) {
      return date.toLocaleTimeString(this.locale, { second: '2-digit' });
    },
    formatAmPm(date) {
      return date.toLocaleTimeString(this.locale, { hour12: true }).slice(-2);
    },
    updateTimes() {
      const now = new Date();
      this.visitorTime = new Date(now.toLocaleString(this.locale, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
      this.myTime = new Date(now.toLocaleString(this.locale, { timeZone: this.myTimezone }));
      this.timeDifference = (this.myTime - this.visitorTime) / (1000 * 60 * 60);
      this.updateMessage();
      this.updateSuggestion();
    },
    updateMessage() {
      if (Math.abs(this.timeDifference) < 0.1) {
        this.message = this.t('inSync');
      } else if (this.timeDifference > 0) {
        this.message = this.t('ahead', { difference: Math.abs(this.timeDifference.toFixed(1)) });
      } else {
        this.message = this.t('behind', { difference: Math.abs(this.timeDifference.toFixed(1)) });
      }
    },
    updateSuggestion() {
      const myHour = this.myTime.getHours();
      if (myHour >= 22 || myHour < 6) {
        this.suggestion = this.t('dreaming');
      } else if (myHour >= 6 && myHour < 9) {
        this.suggestion = this.t('morningCoffee');
      } else if (myHour >= 9 && myHour < 18) {
        this.suggestion = this.t('coding');
      } else {
        this.suggestion = this.t('windingDown');
      }
    },
    setMyTimezone(timezone) {
      try {
        new Date().toLocaleString(this.locale, { timeZone: timezone });
        this.myTimezone = timezone;
      } catch (e) {
        console.error(`Invalid time zone: ${timezone}`);
        // Fallback to a default time zone or show an error message
      }
    },
    t(key, params = {}) {
      let translation = this.translations[this.locale][key] || key;
      Object.keys(params).forEach(param => {
        translation = translation.replace(`{${param}}`, params[param]);
      });
      return translation;
    }
  },
  mounted() {
    this.updateTimes();
    this.timer = setInterval(this.updateTimes, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
}
</script>

<style scoped>
.time-comparison-wrapper {
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
}

.time-comparison {
  max-width: 900px;
  margin: 0 auto;
}

.time-cards-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
}

.time-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 300px;
}

.time-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.star-field {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  perspective: 600px;
  -webkit-perspective: 600px;
  z-index: -1;
}

.star-field .layer {
  box-shadow: -411px -476px #cccccc, 777px -407px #d4d4d4, -387px -477px #fcfcfc, -91px -235px #d4d4d4, 491px -460px #f7f7f7, 892px -128px #f7f7f7, 758px -277px #ededed, 596px 378px #cccccc, 647px 423px whitesmoke, 183px 389px #c7c7c7,
              524px -237px #f0f0f0, 679px -535px #e3e3e3, 158px 399px #ededed, 157px 249px #ededed, 81px -450px #ebebeb, 719px -360px #c2c2c2, -499px 473px #e8e8e8, -158px -349px #d4d4d4, 870px -134px #cfcfcf, 446px 404px #c2c2c2,
              440px 490px #d4d4d4, 414px 507px #e6e6e6, -12px 246px #fcfcfc, -384px 369px #e3e3e3, 641px -413px #fcfcfc, 822px 516px #dbdbdb, 449px 132px #c2c2c2, 727px 146px #f7f7f7, -315px -488px #e6e6e6, 952px -70px #e3e3e3,
              -869px -29px #dbdbdb, 502px 80px #dedede, 764px 342px #e0e0e0, -150px -380px #dbdbdb, 654px -426px #e3e3e3, -325px -263px #c2c2c2, 755px -447px #c7c7c7, 729px -177px #c2c2c2, -682px -391px #e6e6e6, 554px -176px #ededed,
              -85px -428px #d9d9d9, 714px 55px #e8e8e8, 359px -285px #cfcfcf, -362px -508px #dedede, 468px -265px #fcfcfc, 74px -500px #c7c7c7, -514px 383px #dbdbdb, 730px -92px #cfcfcf, -112px 287px #c9c9c9, -853px 79px #d6d6d6,
              828px 475px #d6d6d6, -681px 13px #fafafa, -176px 209px #f0f0f0, 758px 457px #fafafa, -383px -454px #ededed, 813px 179px #d1d1d1, 608px 98px whitesmoke, -860px -65px #c4c4c4, -572px 272px #f7f7f7, 459px 533px #fcfcfc,
              624px -481px #e6e6e6, 790px 477px #dedede, 731px -403px #ededed, 70px -534px #cccccc, -23px 510px #cfcfcf, -652px -237px whitesmoke, -690px 367px #d1d1d1, 810px 536px #d1d1d1, 774px 293px #c9c9c9, -362px 97px #c2c2c2,
              563px 47px #dedede, 313px 475px #e0e0e0, 839px -491px #e3e3e3, -217px 377px #d4d4d4, -581px 239px #c2c2c2, -857px 72px #cccccc, -23px 340px #dedede, -837px 246px white, 170px -502px #cfcfcf, 822px -443px #e0e0e0, 795px 497px #e0e0e0,
              -814px -337px #cfcfcf, 206px -339px #f2f2f2, -779px 108px #e6e6e6, 808px 2px #d4d4d4, 665px 41px #d4d4d4, -564px 64px #cccccc, -380px 74px #cfcfcf, -369px -60px #f7f7f7, 47px -495px #e3e3e3, -383px 368px #f7f7f7, 419px 288px #d1d1d1,
              -598px -50px #c2c2c2, -833px 187px #c4c4c4, 378px 325px whitesmoke, -703px 375px #d6d6d6, 392px 520px #d9d9d9, -492px -60px #c4c4c4, 759px 288px #ebebeb, 98px -412px #c4c4c4, -911px -277px #c9c9c9;
  transform-style: preserve-3d;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 4px;
  width: 4px;
  border-radius: 2px;
}
.star-field .layer:nth-child(1) {
  animation: sf-fly-by-1 5s linear infinite;
}
.star-field .layer:nth-child(2) {
  animation: sf-fly-by-2 5s linear infinite;
}
.star-field .layer:nth-child(3) {
  animation: sf-fly-by-3 5s linear infinite;
}

@keyframes sf-fly-by-1 {
  from { transform: translateZ(-600px); opacity: 0.5; }
  to { transform: translateZ(0); opacity: 0.5; }
}
@keyframes sf-fly-by-2 {
  from { transform: translateZ(-1200px); opacity: 0.5; }
  to { transform: translateZ(-600px); opacity: 0.5; }
}
@keyframes sf-fly-by-3 {
  from { transform: translateZ(-1800px); opacity: 0.5; }
  to { transform: translateZ(-1200px); opacity: 0.5; }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

@media (max-width: 768px) {
  .time-cards-container {
    flex-direction: column;
    align-items: center;
  }
  .time-card {
    width: 100%;
    max-width: none;
    margin-bottom: 1rem;
  }
}
</style>