<!-- src/components/TimelineItem.vue -->
<template>
    <div class="timeline-block">
      <div class="timeline-ico">
        <i class="fa fa-briefcase"></i>
      </div>
      <div class="timeline-header">
        <h3>{{ title }}</h3>
        <p>{{ date }}</p>
      </div>
      <div class="timeline-content">
        <h4>{{ subtitle }}</h4>
        <p>{{ description }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TimelineItem',
    props: {
      title: String,
      date: String,
      subtitle: String,
      description: String
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .timeline-block {
    position: relative;
    padding-bottom: 3rem;
  
    &:last-child {
      padding-bottom: 0;
    }
  
    .timeline-ico {
      height: 4.5rem;
      width: 4.5rem;
      line-height: 4.5rem;
      background-color: var(--secondary-color);
      border-radius: 50%;
      text-align: center;
      color: var(--primary-color);
      position: absolute;
      left: -3.5rem;
      top: 0.3rem;
    }
  
    .timeline-header {
      padding-left: 2rem;
  
      h3 {
        color: var(--text-color);
        font-size: 2rem;
        margin-bottom: 0;
      }
  
      p {
        color: var(--primary-color);
        font-size: 1.2rem;
        margin-top: 0;
      }
    }
  
    .timeline-content {
      padding-left: 2rem;
  
      h4 {
        color: var(--text-color);
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
  
      p {
        color: var(--text-color);
        opacity: 0.8;
      }
    }
  }
  </style>