<template>
    <div v-if="show" class="splash-screen" @click="hideSplashScreen">
      <div class="background-animation"></div>
      <div class="content">
        <svg class="logo" width="200" height="200" viewBox="0 0 200 200">
          <circle class="logo-circle" cx="100" cy="100" r="90" />
          <path class="logo-k" d="M70 50 L70 150 M70 100 L130 50 M70 100 L130 150" />
        </svg>
        <h1 class="glitch" data-text="Kohulan Rajan">Kohulan Rajan</h1>
        <p class="typewriter">Click to enter</p>
      </div>
      <div class="particles"></div>
      <div class="geometric-shapes">
        <div class="shape circle"></div>
        <div class="shape triangle"></div>
        <div class="shape square"></div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, onBeforeUnmount } from 'vue'
  
  export default {
    name: 'SplashScreen',
    setup(props, { emit }) {
      const show = ref(true)
  
      const hideSplashScreen = () => {
        show.value = false
        emit('hidden')
      }
  
      const createParticle = () => {
        const particle = document.createElement('div')
        particle.classList.add('particle')
        particle.style.left = `${Math.random() * 100}%`
        particle.style.top = `${Math.random() * 100}%`
        particle.style.animationDuration = `${Math.random() * 2 + 1}s`
        particle.style.animationDelay = `${Math.random()}s`
        document.querySelector('.particles').appendChild(particle)
      }
  
      let particleInterval
  
      onMounted(() => {
        for (let i = 0; i < 50; i++) {
          createParticle()
        }
  
        particleInterval = setInterval(createParticle, 200)
      })
  
      onBeforeUnmount(() => {
        clearInterval(particleInterval)
      })
  
      return {
        show,
        hideSplashScreen
      }
    }
  }
  </script>
  
  <style scoped>
  .splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: pointer;
    overflow: hidden;
  }
  
  .background-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #ff00ff, #00ffff, #ff00ff);
    background-size: 400% 400%;
    animation: gradientBG 15s ease infinite;
    opacity: 0.1;
  }
  
  .content {
    position: relative;
    z-index: 2;
    text-align: center;
  }
  
  .logo {
    margin-bottom: 2rem;
  }
  
  .logo-circle {
    fill: none;
    stroke: var(--primary-color);
    stroke-width: 4;
    stroke-dasharray: 565;
    stroke-dashoffset: 565;
    animation: drawCircle 2s ease forwards;
  }
  
  .logo-k {
    fill: none;
    stroke: var(--primary-color);
    stroke-width: 4;
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: drawK 2s ease forwards 0.5s;
  }
  
  h1 {
    font-size: 4rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
    position: relative;
  }
  
  .glitch {
    position: relative;
    color: var(--primary-color);
    font-size: 4em;
    letter-spacing: 0.5em;
    animation: glitch-skew 1s infinite linear alternate-reverse;
  }
  
  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim 5s infinite linear alternate-reverse;
  }
  
  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim2 5s infinite linear alternate-reverse;
  }
  
  .typewriter {
    overflow: hidden;
    border-right: 0.15em solid var(--primary-color);
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.15em;
    font-size: 1.2rem;
    color: var(--text-color);
    width: 0;
    animation: 
      typing 1.5s steps(20, end) forwards,
      blink-caret 0.75s step-end infinite;
  }
  
  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .particle {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: var(--primary-color);
    border-radius: 50%;
    animation: float 3s infinite ease-in-out;
  }
  
  .geometric-shapes {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .shape {
    position: absolute;
    opacity: 0.1;
    animation: rotate 20s linear infinite;
  }
  
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    top: 20%;
    left: 20%;
  }
  
  .triangle {
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 86.6px solid var(--primary-color);
    top: 60%;
    right: 20%;
  }
  
  .square {
    width: 80px;
    height: 80px;
    border: 2px solid var(--primary-color);
    bottom: 20%;
    left: 50%;
  }
  
  @keyframes gradientBG {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  @keyframes drawCircle {
    to { stroke-dashoffset: 0; }
  }
  
  @keyframes drawK {
    to { stroke-dashoffset: 0; }
  }
  
  @keyframes glitch-anim {
    0% { clip: rect(44px, 450px, 56px, 0); transform: skew(0.65deg); }
    5% { clip: rect(32px, 450px, 94px, 0); transform: skew(0.23deg); }
    10% { clip: rect(73px, 450px, 6px, 0); transform: skew(0.05deg); }
    15% { clip: rect(15px, 450px, 76px, 0); transform: skew(0.43deg); }
    20% { clip: rect(54px, 450px, 21px, 0); transform: skew(0.08deg); }
    25% { clip: rect(37px, 450px, 98px, 0); transform: skew(0.39deg); }
    30% { clip: rect(80px, 450px, 18px, 0); transform: skew(0.67deg); }
    35% { clip: rect(63px, 450px, 71px, 0); transform: skew(0.86deg); }
    40% { clip: rect(28px, 450px, 40px, 0); transform: skew(0.55deg); }
    45% { clip: rect(1px, 450px, 91px, 0); transform: skew(0.32deg); }
    50% { clip: rect(20px, 450px, 67px, 0); transform: skew(0.78deg); }
    55% { clip: rect(89px, 450px, 14px, 0); transform: skew(0.14deg); }
    60% { clip: rect(69px, 450px, 47px, 0); transform: skew(0.61deg); }
    65% { clip: rect(26px, 450px, 85px, 0); transform: skew(0.03deg); }
    70% { clip: rect(42px, 450px, 33px, 0); transform: skew(0.28deg); }
    75% { clip: rect(75px, 450px, 9px, 0); transform: skew(0.52deg); }
    80% { clip: rect(50px, 450px, 55px, 0); transform: skew(0.71deg); }
    85% { clip: rect(12px, 450px, 86px, 0); transform: skew(0.34deg); }
    90% { clip: rect(79px, 450px, 25px, 0); transform: skew(0.48deg); }
    95% { clip: rect(5px, 450px, 59px, 0); transform: skew(0.19deg); }
    100% { clip: rect(61px, 450px, 30px, 0); transform: skew(0.58deg); }
  }
  
  @keyframes glitch-anim2 {
    0% { clip: rect(86px, 450px, 4px, 0); transform: skew(0.07deg); }
    5% { clip: rect(35px, 450px, 64px, 0); transform: skew(0.29deg); }
    10% { clip: rect(52px, 450px, 74px, 0); transform: skew(0.46deg); }
    15% { clip: rect(66px, 450px, 10px, 0); transform: skew(0.84deg); }
    20% { clip: rect(23px, 450px, 92px, 0); transform: skew(0.37deg); }
    25% { clip: rect(46px, 450px, 16px, 0); transform: skew(0.69deg); }
    30% { clip: rect(72px, 450px, 81px, 0); transform: skew(0.12deg); }
    35% { clip: rect(7px, 450px, 45px, 0); transform: skew(0.53deg); }
    40% { clip: rect(94px, 450px, 39px, 0); transform: skew(0.76deg); }
    45% { clip: rect(18px, 450px, 97px, 0); transform: skew(0.41deg); }
    50% { clip: rect(58px, 450px, 27px, 0); transform: skew(0.62deg); }
    55% { clip: rect(83px, 450px, 3px, 0); transform: skew(0.23deg); }
    60% { clip: rect(38px, 450px, 87px, 0); transform: skew(0.57deg); }
    65% { clip: rect(11px, 450px, 53px, 0); transform: skew(0.31deg); }
    70% { clip: rect(96px, 450px, 8px, 0); transform: skew(0.74deg); }
    75% { clip: rect(29px, 450px, 62px, 0); transform: skew(0.15deg); }
    80% { clip: rect(77px, 450px, 19px, 0); transform: skew(0.43deg); }
    85% { clip: rect(2px, 450px, 88px, 0); transform: skew(0.68deg); }
    90% { clip: rect(49px, 450px, 31px, 0); transform: skew(0.09deg); }
    95% { clip: rect(70px, 450px, 36px, 0); transform: skew(0.51deg); }
    100% { clip: rect(13px, 450px, 82px, 0); transform: skew(0.34deg); }
  }
  
  @keyframes glitch-skew {
    0% { transform: skew(1deg); }
    10% { transform: skew(-4deg); }
    20% { transform: skew(2deg); }
    30% { transform: skew(-3deg); }
    40% { transform: skew(3deg); }
    50% { transform: skew(-1deg); }
    60% { transform: skew(5deg); }
    70% { transform: skew(-2deg); }
    80% { transform: skew(4deg); }
    90% { transform: skew(-5deg); }
    100% { transform: skew(0deg); }
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 14ch }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--primary-color) }
  }
  
  @keyframes float {
    0%, 100% { transform: translateY(0) rotate(0deg); }
    50% { transform: translateY(-20px) rotate(180deg); }
  }
  
  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
    .glitch {
      font-size: 3em;
    }
  
    .typewriter {
      font-size: 1rem;
    }
  }
  </style>