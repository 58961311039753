<template>
  <div id="app">
    <SplashScreen v-if="showSplash" @hidden="hideSplash" />
    <sf-vue-animated-cursor
      target="#app"
      teleport-to="body"
      :color="'yellow'"
      :outer-size="30"
      :inner-size="8"
      :outer-scale="1.7"
      :inner-scale="0.6"
    />
    <Noise />
    <Header />
    <router-view v-slot="{ Component }">
      <transition name="page" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <Footer />
  </div>
</template>

<script>
import { ref } from 'vue'
import { SfVueAnimatedCursor } from 'sf-vue-animated-cursor'
import Header from '@/components/HeaderComponent.vue'
import Footer from '@/components/FooterComponent.vue'
import Noise from '@/components/NoiseComponent.vue'
import SplashScreen from '@/components/SplashScreen.vue'

export default {
  name: 'App',
  components: {
    SfVueAnimatedCursor,
    Header,
    Footer,
    Noise,
    SplashScreen
  },
  setup() {
    const showSplash = ref(true)

    const hideSplash = () => {
      showSplash.value = false
    }

    return {
      showSplash,
      hideSplash
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;700&display=swap');

:root {
  --primary-color: #dcff46;
  --secondary-color: #1c1d21;
  --text-color: #ffffff;
  --background-color: #000000;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Bahnschrift', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  cursor: none; /* Hide the default cursor */
}

a, button {
  cursor: none; /* Hide the default cursor for interactive elements */
}

#app {
  position: relative;
  z-index: 1;
}

// Page transition
.page-enter-active,
.page-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

// Animation classes
.fade-up {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.6s ease-out;
}

.scale-in {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.is-visible {
  opacity: 1;
  transform: translateY(0) scale(1);
}

// Stagger animation for lists
@for $i from 1 through 10 {
  .stagger-item:nth-child(#{$i}) {
    transition-delay: #{$i * 0.1}s;
  }
}

// Responsive typography
html {
  font-size: 16px;
  
  @media (min-width: 768px) {
    font-size: 18px;
  }
  
  @media (min-width: 1200px) {
    font-size: 20px;
  }
}

// Common layout styles
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section {
  padding: 4rem 0;
  
  @media (min-width: 768px) {
    padding: 6rem 0;
  }
}

// Utility classes
.text-center {
  text-align: center;
}

.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem; }
.mb-3 { margin-bottom: 3rem; }
.mb-4 { margin-bottom: 4rem; }

.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 2rem; }
.mt-3 { margin-top: 3rem; }
.mt-4 { margin-top: 4rem; }
</style>