<!-- src/components/EnhancedHeaderComponent.vue -->
<template>
  <header class="header" :class="{ 'header-scrolled': scrolled, 'nav-open': menuOpen }">
    <div class="header-inner">
      <div class="logo">
        <a href="/" @click.prevent="smoothScroll('/')">
          <span class="logo-text">K</span>
          <span class="logo-letter" v-for="(letter, index) in 'OHULAN'" :key="index"
                :style="{ 'animation-delay': `${index * 0.1}s` }">{{ letter }}</span>
          <span class="logo-dot"></span>
          <span class="logo-text">R</span>
        </a>
      </div>
      <nav class="nav" :class="{ 'nav-open': menuOpen }">
        <ul>
          <li v-for="(item, index) in menuItems" :key="item.href">
            <a 
              :href="item.href" 
              @click.prevent="smoothScroll(item.href)"
              :style="{ 'animation-delay': `${index * 0.1}s` }"
            >
              {{ item.text }}
              <span class="nav-dot"></span>
            </a>
          </li>
        </ul>
      </nav>
      <button class="menu-toggle" @click="toggleMenu" :aria-expanded="menuOpen">
        <span class="menu-toggle-inner"></span>
      </button>
    </div>
    <div class="particle-container">
      <div v-for="n in 20" :key="n" class="particle"></div>
    </div>
  </header>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'EnhancedHeaderComponent',
  setup() {
    const router = useRouter();
    const menuOpen = ref(false);
    const scrolled = ref(false);

    const menuItems = [
      { href: '/', text: 'Home' },
      { href: '/about', text: 'About' },
      { href: '/resume', text: 'Resume' },
      { href: '/publications', text: 'Publications' },
      { href: '/portfolio', text: 'Portfolio' },
      { href: '/contact', text: 'Contact' },
    ];

    const smoothScroll = (target) => {
      if (target.startsWith('#')) {
        const element = document.querySelector(target);
        if (element) {
          window.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth',
          });
        }
      } else {
        router.push(target);
      }
      closeMenu();
    };

    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value;
      document.body.style.overflow = menuOpen.value ? 'hidden' : '';
    };

    const closeMenu = () => {
      menuOpen.value = false;
      document.body.style.overflow = '';
    };

    const handleScroll = () => {
      scrolled.value = window.scrollY > 50;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      menuItems,
      menuOpen,
      scrolled,
      smoothScroll,
      toggleMenu,
      closeMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  --primary-color: #FFD700;
  --text-color: #ffffff;
  --background-color: rgba(20, 20, 20, 0.98);
  --animation-curve: cubic-bezier(0.645, 0.045, 0.355, 1);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: all 0.5s var(--animation-curve);
  backdrop-filter: blur(15px);

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 3rem;
    max-width: 1400px;
    margin: 0 auto;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 40px 40px;
    transition: all 0.5s var(--animation-curve);
    background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.18);
  }

  &-scrolled .header-inner {
    padding: 1rem 3rem;
    background: var(--background-color);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.4);
  }

  .logo {
    a {
      display: flex;
      align-items: center;
      color: var(--primary-color);
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 2.5rem;
      letter-spacing: 3px;
      text-decoration: none;
      transition: all 0.3s ease;

      .logo-text, .logo-letter {
        display: inline-block;
        transition: all 0.3s ease;
      }

      .logo-letter {
        opacity: 0;
        transform: translateY(20px);
        animation: fadeInUp 0.5s forwards;
      }

      .logo-dot {
        width: 12px;
        height: 12px;
        background-color: var(--primary-color);
        border-radius: 50%;
        margin: 0 8px;
        transition: all 0.3s ease;
      }

      &:hover {
        color: var(--text-color);
        text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);

        .logo-text, .logo-letter {
          transform: scale(1.1) rotate(5deg);
        }
        .logo-dot {
          transform: scale(1.8);
          box-shadow: 0 0 15px var(--primary-color);
          animation: pulse 1.5s infinite;
        }
      }
    }
  }

  .nav {
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-left: 3rem;

        a {
          color: var(--text-color);
          text-decoration: none;
          font-size: 1.3rem;
          position: relative;
          padding-bottom: 5px;
          transition: all 0.3s ease;

          .nav-dot {
            position: absolute;
            bottom: -4px;
            left: 50%;
            width: 6px;
            height: 6px;
            background-color: var(--primary-color);
            border-radius: 50%;
            transform: translateX(-50%) scale(0);
            transition: all 0.3s ease;
          }

          &:hover, &:focus {
            color: var(--primary-color);
            text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);

            .nav-dot {
              transform: translateX(-50%) scale(1);
              box-shadow: 0 0 10px var(--primary-color);
              animation: pulse 1.5s infinite;
            }
          }
        }
      }
    }
  }

  .menu-toggle {
    display: none;
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1.5rem;
    cursor: pointer;
    position: relative;
    z-index: 1001;
    transition: all 0.3s ease;

    &-inner {
      width: 30px;
      height: 2px;
      background-color: var(--text-color);
      position: absolute;
      top: 50%;
      left: 0;
      transition: all 0.3s ease;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--text-color);
        left: 0;
        transition: all 0.3s ease;
      }

      &::before {
        top: -8px;
      }

      &::after {
        bottom: -8px;
      }
    }

    &:hover {
      transform: scale(1.1);

      .menu-toggle-inner {
        background-color: var(--primary-color);

        &::before,
        &::after {
          background-color: var(--primary-color);
        }
      }
    }
  }

  &-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 999;
    backdrop-filter: blur(10px);

    &.active {
      display: block;
      opacity: 1;
    }
  }

  .particle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }

  .particle {
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: var(--primary-color);
    border-radius: 50%;
    opacity: 0;
    animation: particleAnimation 3s ease-in infinite;

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        left: random(100) * 1%;
        top: random(100) * 1%;
        animation-delay: random(3000) * 1ms;
      }
    }
  }

  @media (max-width: 768px) {
    .nav {
      position: fixed;
      top: 0;
      right: -100%;
      width: 80%;
      height: 100vh;
      background-color: var(--background-color);
      backdrop-filter: blur(15px);
      transition: all 0.5s var(--animation-curve);
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1000;

      &-open {
        right: 0;
      }

      ul {
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;

        li {
          margin: 2rem 0;

          a {
            font-size: 1.8rem;
            opacity: 0;
            transform: translateX(50px);
            animation: slideIn 0.5s forwards;
          }
        }
      }
    }

    .menu-toggle {
      display: block;
    }

    .header-backdrop {
      display: block;
    }

    &.nav-open {
      .menu-toggle-inner {
        background-color: transparent;

        &::before {
          transform: translateY(8px) rotate(45deg);
        }

        &::after {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

@keyframes particleAnimation {
  0% {
    transform: translateY(0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100px) scale(1);
    opacity: 0;
  }
}
</style>