<template>
  <div
    ref="noiseContainer"
    class="noise"
    :style="{
      opacity,
      mixBlendMode: blendMode,
      backgroundColor: color,
    }"
    :class="{ 'noise--animated': animated }"
  >
    <svg width="0" height="0">
      <filter :id="filterId">
        <feTurbulence
          type="fractalNoise"
          :baseFrequency="baseFrequency"
          :numOctaves="numOctaves"
          :seed="seed"
          stitchTiles="stitch"
        />
        <feColorMatrix type="saturate" :values="saturation" />
      </filter>
    </svg>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue'

export default {
  name: 'NoiseComponent',
  props: {
    opacity: {
      type: Number,
      default: 0.05,
      validator: (value) => value >= 0 && value <= 1,
    },
    blendMode: {
      type: String,
      default: 'overlay',
      validator: (value) => ['normal', 'multiply', 'screen', 'overlay', 'darken', 'lighten', 'color-dodge', 'color-burn', 'hard-light', 'soft-light', 'difference', 'exclusion'].includes(value),
    },
    animated: {
      type: Boolean,
      default: true,
    },
    baseFrequency: {
      type: Number,
      default: 0.65,
    },
    numOctaves: {
      type: Number,
      default: 3,
    },
    seed: {
      type: Number,
      default: 0,
    },
    saturation: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: '#000000',
    },
    speed: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const noiseContainer = ref(null)
    let animationId = null
    
    // Generate a unique ID for the filter
    const uniqueId = `noise-${Math.random().toString(36).substr(2, 9)}`
    const filterId = computed(() => uniqueId)

    const animate = () => {
      if (props.animated && noiseContainer.value) {
        const time = Date.now() / 1000 * props.speed
        noiseContainer.value.style.filter = `url(#${filterId.value}) translate3d(${Math.sin(time) * 10}px, ${Math.cos(time) * 10}px, 0)`
        animationId = requestAnimationFrame(animate)
      }
    }

    onMounted(() => {
      if (props.animated) {
        animate()
      }
    })

    onUnmounted(() => {
      if (animationId) {
        cancelAnimationFrame(animationId)
      }
    })

    return {
      noiseContainer,
      filterId,
    }
  },
}
</script>

<style scoped>
.noise {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.noise--animated {
  will-change: filter;
}
</style>