<!-- src/views/UltraEnhancedAboutView.vue -->
<template>
  <section id="about" class="about">
    <ParallaxBackground image="/images/about-bg.jpg" :speed="0.5" />
    <div class="particle-system"></div>
    <div class="container">
      <SectionHeader title="About Me" subtitle="Let me introduce myself." />
      
      <div class="row section-intro">
        <div class="col-twelve">
          <div class="intro-info" ref="introInfo" v-motion-slide-visible-once-bottom>
            <div class="profile-pic-wrapper">
              <div class="profile-pic-container">
                <img src="@/assets/images/profile-pic.jpg" alt="Profile Picture" class="profile-pic">
                <div class="profile-pic-overlay"></div>
              </div>
              <div class="orbit-container">
                <div class="orbit"></div>
                <div class="orbit"></div>
                <div class="orbit"></div>
              </div>
            </div>
            <p class="lead" v-motion-slide-visible-once-bottom>
              I am currently a postdoctoral researcher in the 
              <a href="https://cheminf.uni-jena.de/" target="_blank" rel="noopener noreferrer">Steinbeck group</a> 
              at Friedrich-Schiller University in Jena, Germany. My PhD research, under the supervision of 
              <a href="https://cheminf.uni-jena.de/members/steinbeck/" target="_blank" rel="noopener noreferrer">Prof. Dr. Christoph Steinbeck</a> 
              and 
              <a href="https://www.w-hs.de/service/informationen-zur-person/person/zielesny/" target="_blank" rel="noopener noreferrer">Prof. Dr. Achim Zielesny</a>, 
              focused on applying deep learning techniques to chemical data mining, computer vision, and natural language processing (NLP) to enhance the understanding of chemical structure representations. I specialize in developing open-source cheminformatics tools, including the 
              <a href="https://decimer.ai" target="_blank" rel="noopener noreferrer">DECIMER</a> 
              project for chemical image recognition using transformers.
            </p>
          </div>
        </div>
      </div>

      <div class="row about-content">
        <div class="col-full" v-motion-slide-visible-once-bottom>
          <p class="animated-text">
            My research leverages cloud-based infrastructure, predominantly the Google Cloud Platform, and Tensor Processing Units (TPUs) for high-performance model training and deployment. Additionally, I contribute as a teaching assistant, leading cheminformatics and Python programming courses. Beyond academic research, I am actively involved in creative work, such as photography, filmmaking, and poetry.
          </p>
        </div>
      </div>

      <div class="row info-section">
        <div class="col-full" v-motion-slide-visible-once-bottom>
          <h3 class="glitch-effect" data-text="Profile">Profile</h3>
          <ul class="info-list">
            <li v-for="(info, index) in profileInfo" :key="info.label" class="info-item">
              <strong>{{ info.label }}:</strong>
              <span v-motion-slide-visible-once-bottom :delay="index * 100">
                <template v-if="info.link">
                  <a :href="info.link" target="_blank" rel="noopener noreferrer" class="neon-link">{{ info.value }}</a>
                </template>
                <template v-else>
                  {{ info.value }}
                </template>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="row button-section" v-motion-slide-visible-once-bottom>
        <div class="col-twelve">
          <router-link to="/contact" class="button pulse">Contact Me</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useMotion } from '@vueuse/motion';
import { useRouter } from 'vue-router';
import SectionHeader from '@/components/SectionHeader.vue';
import ParallaxBackground from '@/components/ParallaxBackground.vue';

export default {
  name: 'UltraEnhancedAboutView',
  components: {
    SectionHeader,
    ParallaxBackground,
  },
  setup() {
    const router = useRouter();
    const introInfo = ref(null);
    useMotion(introInfo);

    const profileInfo = ref([
      { label: 'Fullname', value: 'Kohulan Rajan' },
      { label: 'Job', value: 'Postdoctoral Researcher' },
      { 
        label: 'Website', 
        value: 'www.kohulanr.com',
        link: 'https://www.kohulanr.com'
      },
      { 
        label: 'Email', 
        value: 'kohulan.rajan@uni-jena.de',
        link: 'mailto:kohulan.rajan@uni-jena.de'
      },
      { 
        label: 'Project Website', 
        value: 'www.decimer.ai',
        link: 'https://www.decimer.ai'
      },
    ]);

    const scrollTo = (event) => {
      event.preventDefault();
      const target = event.target.getAttribute('href');
      
      // Check if the target is on the current page
      const element = document.querySelector(target);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        // If the element is not found, navigate to the appropriate route
        router.push({ path: '/', hash: target });
      }
    };

    onMounted(() => {
      initParticleSystem();
    });

    const initParticleSystem = () => {
      const particleContainer = document.querySelector('.particle-system');
      for (let i = 0; i < 50; i++) {
        const particle = document.createElement('div');
        particle.classList.add('particle');
        particle.style.setProperty('--x', `${Math.random() * 100}vw`);
        particle.style.setProperty('--y', `${Math.random() * 100}vh`);
        particle.style.setProperty('--delay', `${Math.random() * 5}s`);
        particleContainer.appendChild(particle);
      }
    };

    return {
      introInfo,
      profileInfo,
      scrollTo
    };
  }
}
</script>

<style lang="scss" scoped>
.about {
  --primary-color: #DCFF46;
  --secondary-color: #1A1A1A;
  --text-color: #FFFFFF;
  --glow-color: rgba(220, 255, 70, 0.6);

  position: relative;
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 8rem 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color), var(--primary-color));
    animation: gradientFlow 5s linear infinite;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;
    z-index: 2;
  }

  .section-intro {
    text-align: center;
    margin-bottom: 4rem;
  }

  .intro-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4rem;

    .profile-pic-wrapper {
      position: relative;
      width: 200px;
      height: 200px;
      flex-shrink: 0;
      
      .profile-pic-container {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        
        &::before {
          content: '';
          position: absolute;
          top: -5px;
          left: -5px;
          right: -5px;
          bottom: -5px;
          background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
          z-index: -1;
          border-radius: inherit;
          animation: rotate 5s linear infinite;
        }

        .profile-pic {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          transition: transform 0.3s ease;
        }

        .profile-pic-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        &:hover {
          .profile-pic {
            transform: scale(1.1);
          }
          .profile-pic-overlay {
            opacity: 1;
          }
        }
      }

      .orbit-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;

        .orbit {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 2px solid rgba(220, 255, 70, 0.3);
          border-radius: 50%;

          &:nth-child(1) {
            animation: orbit 10s linear infinite;
          }

          &:nth-child(2) {
            width: 120%;
            height: 120%;
            animation: orbit 15s linear infinite reverse;
          }

          &:nth-child(3) {
            width: 140%;
            height: 140%;
            animation: orbit 20s linear infinite;
          }
        }
      }
    }

    .lead {
      flex: 1;
      min-width: 300px;
      font-size: 1.1rem;
      line-height: 1.8;
      text-align: left;

      a {
        color: var(--primary-color);
        text-decoration: none;
        position: relative;
        
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: -2px;
          left: 0;
          background-color: var(--primary-color);
          transform: scaleX(0);
          transform-origin: bottom right;
          transition: transform 0.3s ease;
        }

        &:hover::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }

  .about-content, .info-section {
    margin-top: 4rem;

    h3 {
      font-size: 2rem;
      margin-bottom: 2rem;
      color: var(--primary-color);
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 50px;
        height: 2px;
        background-color: var(--primary-color);
      }
    }

    .animated-text {
      font-size: 1.1rem;
      line-height: 1.8;
      margin-bottom: 2rem;
      opacity: 0;
      transform: translateY(20px);
      animation: fadeInUp 1s forwards;
      animation-delay: 0.5s;
    }
  }

  .info-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .info-item {
      flex: 1 1 calc(50% - 1rem);
      display: flex;
      align-items: center;
      opacity: 0;
      transform: translateX(-20px);
      animation: fadeInLeft 0.5s forwards;
      animation-delay: calc(var(--index) * 0.1s);

      strong {
        min-width: 130px;
        color: var(--primary-color);
        font-size: 1.1rem;
      }

      span {
        font-size: 1.1rem;
      }

      .neon-link {
        color: var(--primary-color);
        text-decoration: none;
        text-shadow: 0 0 5px var(--glow-color);
        transition: all 0.3s ease;

        &:hover {
          text-shadow: 0 0 10px var(--glow-color), 0 0 20px var(--glow-color);
        }
      }
    }
  }

  .button-section {
    text-align: center;
    margin-top: 4rem;

    .button {
      display: inline-block;
      padding: 1rem 3rem;
      background-color: transparent;
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
      text-decoration: none;
      font-weight: 700;
      font-size: 1.1rem;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;
      text-shadow: 0 0 5px var(--glow-color);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        transition: all 0.3s ease;
        z-index: -1;
      }

      &:hover {
        color: var(--secondary-color);
        text-shadow: none;

        &::before {
          left: 0;
        }
      }
    }
  }

  .particle-system {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }

  .particle {
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: var(--primary-color);
    border-radius: 50%;
    opacity: 0.5;
    animation: particleFloat 20s linear infinite;
    animation-delay: var(--delay);
  }

  .glitch-effect {
    position: relative;
    color: var(--primary-color);
    
    &::before,
    &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &::before {
      left: 2px;
      text-shadow: -1px 0 #ff00c1;
      clip: rect(44px, 450px, 56px, 0);
      animation: glitch-anim 5s infinite linear alternate-reverse;
    }

    &::after {
      left: -2px;
      text-shadow: -1px 0 #00fff9;
      clip: rect(44px, 450px, 56px, 0);
      animation: glitch-anim 5s infinite linear alternate-reverse;
    }
  }

  @media (max-width: 768px) {
    .intro-info {
      flex-direction: column;
      text-align: center;

      .lead {
        text-align: center;
      }
    }

    .info-list .info-item {
      flex: 1 1 100%;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes orbit {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes particleFloat {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(-100vh) translateX(100vw);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(57px, 9999px, 12px, 0);
  }
  5% {
    clip: rect(78px, 9999px, 95px, 0);
  }
  10% {
    clip: rect(91px, 9999px, 68px, 0);
  }
  15% {
    clip: rect(83px, 9999px, 75px, 0);
  }
  20% {
    clip: rect(16px, 9999px, 73px, 0);
  }
  25% {
    clip: rect(57px, 9999px, 64px, 0);
  }
  30% {
    clip: rect(66px, 9999px, 9px, 0);
  }
  35% {
    clip: rect(39px, 9999px, 30px, 0);
  }
  40% {
    clip: rect(15px, 9999px, 59px, 0);
  }
  45% {
    clip: rect(95px, 9999px, 71px, 0);
  }
  50% {
    clip: rect(35px, 9999px, 93px, 0);
  }
  55% {
    clip: rect(85px, 9999px, 72px, 0);
  }
  60% {
    clip: rect(50px, 9999px, 80px, 0);
  }
  65% {
    clip: rect(17px, 9999px, 31px, 0);
  }
  70% {
    clip: rect(9px, 9999px, 47px, 0);
  }
  75% {
    clip: rect(85px, 9999px, 16px, 0);
  }
  80% {
    clip: rect(94px, 9999px, 23px, 0);
  }
  85% {
    clip: rect(86px, 9999px, 96px, 0);
  }
  90% {
    clip: rect(39px, 9999px, 15px, 0);
  }
  95% {
    clip: rect(33px, 9999px, 55px, 0);
  }
  100% {
    clip: rect(18px, 9999px, 27px, 0);
  }
}

// Add a subtle pulsating background
.about::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, var(--glow-color) 0%, transparent 70%);
  opacity: 0.1;
  z-index: 0;
  animation: pulsate 10s ease-in-out infinite;
}

@keyframes pulsate {
  0%, 100% { transform: scale(1); opacity: 0.1; }
  50% { transform: scale(1.1); opacity: 0.2; }
}

// Add a typing effect to the lead paragraph
.lead {
  position: relative;
  overflow: hidden;

  &::after {
    content: '|';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20px;
    background-color: var(--secondary-color);
    animation: typing 3s steps(40) 1s forwards, blink 0.7s infinite;
  }
}

@keyframes typing {
  to { width: 0; }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

// Add a hover effect to the info items
.info-item {
  transition: transform 0.3s ease;

  &:hover {
    transform: translateX(10px);
  }
}

// Add a 3D flip effect to the profile picture on hover
.profile-pic-container {
  perspective: 1000px;
  transition: transform 0.6s;
  transform-style: preserve-3d;

  &:hover {
    transform: rotateY(180deg);
  }

  .profile-pic,
  .profile-pic-overlay {
    backface-visibility: hidden;
  }

  .profile-pic-overlay {
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--primary-color);
  }
}

// Add a shimmering effect to links
a:not(.button) {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      var(--glow-color),
      transparent
    );
    transition: all 0.6s;
  }

  &:hover::before {
    left: 100%;
  }
}

// Add a floating animation to the button
.button {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}
</style>