<!-- src/components/ParallaxBackground.vue -->
<template>
  <div class="parallax-background">
    <div 
      v-for="(layer, index) in layers" 
      :key="index" 
      class="parallax-layer"
      :style="getLayerStyle(layer, index)"
    ></div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';

export default {
  name: 'ParallaxBackground',
  props: {
    layers: {
      type: Array,
      default: () => [
        { image: require('@/assets/images/heroz-bg.jpg'), speed: 0.1 },
        { image: require('@/assets/images/heroz-bg.jpg'), speed: 0.3 },
        { image: require('@/assets/images/heroz-bg.jpg'), speed: 0.5 },
      ],
    },
  },
  setup() {
    const scrollY = ref(0);

    const handleScroll = () => {
      scrollY.value = window.pageYOffset;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    const getLayerStyle = (layer, index) => {
      return computed(() => ({
        backgroundImage: `url(${layer.image})`,
        transform: `translateY(${scrollY.value * layer.speed}px)`,
        zIndex: -10 + index,
      }));
    };

    return {
      getLayerStyle,
    };
  },
};
</script>

<style lang="scss" scoped>
.parallax-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  will-change: transform;
  transition: transform 0.1s cubic-bezier(0,0,0.25,1);
}
</style>