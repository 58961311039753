<!-- src/components/SectionHeader.vue -->
<template>
    <h2 class="section-header" data-scroll>
      {{ title }}
      <span class="underline"></span>
    </h2>
  </template>
  
  <script>
  export default {
    name: 'SectionHeader',
    props: {
      title: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .section-header {
    position: relative;
    display: inline-block;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  
    .underline {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 0;
      height: 3px;
      background-color: var(--primary-color);
      transition: width 0.6s ease-out;
    }
  
    &.is-visible .underline {
      width: 100%;
    }
  }
  </style>