<!-- src/views/HomeView.vue -->
<template>
  <div class="home">
    <ParallaxBackground />


    <section id="intro" class="intro">
      <div class="intro-overlay"></div>
      <div class="intro-content">
        <div class="row">
          <div class="col-twelve">
            <h5 v-motion-slide-visible-once-bottom>Hello, I am</h5>
            <h1 v-motion-slide-visible-once-bottom>
            <span class="gradient-accent">Kohulan Rajan.</span>
          </h1>
            <p class="intro-position" v-motion-slide-visible-once-bottom>
              <span v-for="(role, index) in roles" :key="index" class="role-text">{{ role }}</span>
            </p>
            <router-link 
              to="/about" 
              class="button stroke"
              v-motion-slide-visible-once-bottom
            >
              Get to know me
            </router-link>
          </div>
        </div>
      </div>
      <ScrollIndicator />
    </section>

    <section id="now-playing" class="now-playing" v-motion-fade-visible-once>
      <div class="container">
        <h2 class="section-title">Now Playing</h2>
        <p>Check out what I'm listening to right now!</p>
        <div class="now-playing-content" v-motion-slide-visible-once-bottom>
          <a href="https://natemoo-re-3p2ix14kb-kohulan.vercel.app/now-playing?open" target="_blank" rel="noopener noreferrer">
            <img 
              :src="nowPlayingImageUrl" 
              width="512" 
              height="100" 
              alt="Kohulan's Now Playing"
              @load="checkNowPlaying"
            >
          </a>
        </div>
      </div>
    </section>


    <section id="skills" class="skills" v-motion-fade-visible-once>
      <div class="container">
        <h2 class="section-title">My Expertise</h2>
        <div class="skills-grid">
          <div v-for="(skill, index) in skills" :key="skill.name" 
               class="skill-item"
               v-motion
               :initial="{ opacity: 0, y: 50 }"
               :enter="{ opacity: 1, y: 0, transition: { delay: index * 100 } }">
            <div class="skill-icon">
              <i :class="skill.icon"></i>
            </div>
            <h3>{{ skill.name }}</h3>
            <p>{{ skill.description }}</p>
          </div>
        </div>
      </div>
    </section>

    <section id="recent-work" class="recent-work py-16" v-motion-fade-visible-once>
      <div class="container mx-auto px-4">
        <h2 class="section-title text-4xl font-bold text-center mb-12">Recent Work</h2>
        <div class="work-grid grid grid-cols-1 md:grid-cols-2 gap-8">
          <div v-for="(work, index) in recentWorks" :key="work.title" 
               class="work-item overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl"
               v-motion
               :initial="{ opacity: 0, scale: 0.8 }"
               :enter="{ opacity: 1, scale: 1, transition: { delay: index * 200 } }">
            <div class="work-image">
              <img :src="work.image" :alt="work.title" class="w-full h-48 object-cover">
            </div>
            <div class="work-info p-6">
              <h3 class="text-xl font-semibold mb-2">{{ work.title }}</h3>
              <p class="mb-4">{{ work.description }}</p>
              <a :href="work.link" target="_blank" rel="noopener noreferrer" 
                 class="learn-more inline-block px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-300">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="time-comparison" class="time-comparison py-16" v-motion-fade-visible-once>
  <div class="container">
    <h2 class="section-title">Let's Connect Across Time Zones</h2>
    <TimeComparison />
  </div>
</section>

    <section id="cta" class="cta" v-motion-fade-visible-once>
      <ParticleSystem />
      <div class="container">
        <h2 class="section-title">Interested in collaborating?</h2>
        <p>I'm always open to discussing product design work or partnership opportunities.</p>
        <router-link to="/contact" class="button pulse">Start a conversation</router-link>
      </div>
      <div class="particles"></div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import ParallaxBackground from '@/components/ParallaxBackground.vue'
import ParticleSystem from '@/components/ParticleSystem.vue'
import ScrollIndicator from '@/components/ScrollIndicator.vue'
import TimeComparison from '@/components/TimeComparison.vue'

export default {
  name: 'HomeView',
  components: {
    ParallaxBackground,
    ParticleSystem,
    ScrollIndicator,
    TimeComparison
  },
  setup() {
    const roles = ref(['Researcher','AI-driven cheminformatician', 'Photographer'])
    const currentRoleIndex = ref(0)

    const skills = [
  { 
    name: 'Cheminformatics', 
    icon: 'fas fa-flask' 
  },
  { 
    name: 'AI & Deep Learning', 
    icon: 'fas fa-network-wired' 
  },
  { 
    name: 'Database & Data Science', 
    icon: 'fas fa-database' 
  },
  { 
    name: 'Photography', 
    icon: 'fas fa-camera' 
  },
  { 
    name: 'Writing', 
    icon: 'fas fa-pen' 
  }
];



    const recentWorks = [
      { 
        title: 'STOUT V2.0',
        description: 'SMILES to IUPAC name conversion tool',
        image: require('@/assets/images/STOUT.png'),
        link: 'https://stout.decimer.ai'
      },
      { 
        title: 'COCONUT V2.0',
        description: 'The COlleCtion of Open NatUral producTs',
        image: require('@/assets/images/coconut.png'),
        link: 'https://coconut.naturalproducts.net'
      }
    ]

    const nowPlayingImageUrl = ref('https://natemoo-re-3p2ix14kb-kohulan.vercel.app/now-playing')
    const isPlaying = ref(false)

    const scrollToNextSection = () => {
      const nowPlayingSection = document.getElementById('now-playing')
      if (nowPlayingSection) {
        nowPlayingSection.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const checkNowPlaying = async () => {
      try {
        const response = await fetch('https://natemoo-re-3p2ix14kb-kohulan.vercel.app/now-playing')
        const data = await response.text()
        isPlaying.value = !data.includes('Nothing playing')
      } catch (error) {
        console.error('Error checking Now Playing status:', error)
        isPlaying.value = false
      }
    }

    const createParticle = (container) => {
      const particle = document.createElement('div')
      particle.classList.add('particle')
      particle.style.left = `${Math.random() * 100}%`
      particle.style.top = `${Math.random() * 100}%`
      particle.style.animationDuration = `${Math.random() * 2 + 1}s`
      particle.style.animationDelay = `${Math.random()}s`
      container.appendChild(particle)
    }

    onMounted(() => {

      setInterval(() => {
        currentRoleIndex.value = (currentRoleIndex.value + 1) % roles.value.length
      }, 3000)

      const particlesContainer = document.querySelector('.particles')
      if (particlesContainer) {
        for (let i = 0; i < 50; i++) {
          createParticle(particlesContainer)
        }
      }

      // Refresh the Now Playing image and status every 30 seconds
      const refreshInterval = setInterval(() => {
        nowPlayingImageUrl.value = `https://natemoo-re-3p2ix14kb-kohulan.vercel.app/now-playing?${Date.now()}`
        checkNowPlaying()
      }, 30000)

      // Initial check
      checkNowPlaying()

      onUnmounted(() => {
        clearInterval(refreshInterval)
      })
    })

    return {
      roles,
      currentRoleIndex,
      skills,
      recentWorks,
      nowPlayingImageUrl,
      isPlaying,
      scrollToNextSection,
      checkNowPlaying
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  overflow-x: hidden;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;

  h1 {
    font-size: 4rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  .typewriter {
    overflow: hidden;
    border-right: .15em solid var(--primary-color);
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .1em;
    font-size: 1.2rem;
    color: var(--text-color);
    animation: 
      typing 1s steps(14, end),
      blink-caret .5s step-end infinite;
  }
}

.time-comparison-section {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, #1a202c, #2d3748);


  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
}

.glitch {
  position: relative;
  color: var(--primary-color);
  font-size: 4em;
  letter-spacing: 0.5em;
  animation: glitch-skew 1s infinite linear alternate-reverse;

  &::before,
  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim 5s infinite linear alternate-reverse;
  }

  &::after {
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim2 5s infinite linear alternate-reverse;
  }
}

@keyframes typing {
  from { width: 0 }
  to { width: 14ch }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: var(--primary-color); }
}

@keyframes glitch-anim {
  0% {
    clip: rect(44px, 9999px, 56px, 0);
    transform: skew(0.65deg);
  }
  5% {
    clip: rect(32px, 9999px, 94px, 0);
    transform: skew(0.23deg);
  }
  10% {
    clip: rect(73px, 9999px, 6px, 0);
    transform: skew(0.05deg);
  }
  15% {
    clip: rect(15px, 9999px, 76px, 0);
    transform: skew(0.43deg);
  }
  20% {
    clip: rect(54px, 9999px, 21px, 0);
    transform: skew(0.08deg);
  }
  25% {
    clip: rect(37px, 9999px, 98px, 0);
    transform: skew(0.39deg);
  }
  30% {
    clip: rect(80px, 9999px, 18px, 0);
    transform: skew(0.67deg);
  }
  35% {
    clip: rect(63px, 9999px, 71px, 0);
    transform: skew(0.86deg);
  }
  40% {
    clip: rect(28px, 9999px, 40px, 0);
    transform: skew(0.55deg);
  }
  45% {
    clip: rect(1px, 9999px, 91px, 0);
    transform: skew(0.32deg);
  }
  50% {
    clip: rect(20px, 9999px, 67px, 0);
    transform: skew(0.78deg);
  }
  55% {
    clip: rect(89px, 9999px, 14px, 0);
    transform: skew(0.14deg);
  }
  60% {
    clip: rect(69px, 9999px, 47px, 0);
    transform: skew(0.61deg);
  }
  65% {
    clip: rect(26px, 9999px, 85px, 0);
    transform: skew(0.03deg);
  }
  70% {
    clip: rect(42px, 9999px, 33px, 0);
    transform: skew(0.28deg);
  }
  75% {
    clip: rect(75px, 9999px, 9px, 0);
    transform: skew(0.52deg);
  }
  80% {
    clip: rect(50px, 9999px, 55px, 0);
    transform: skew(0.71deg);
  }
  85% {
    clip: rect(12px, 9999px, 86px, 0);
    transform: skew(0.34deg);
  }
  90% {
    clip: rect(79px, 9999px, 25px, 0);
    transform: skew(0.48deg);
  }
  95% {
    clip: rect(5px, 9999px, 59px, 0);
    transform: skew(0.19deg);
  }
  100% {
    clip: rect(61px, 9999px, 30px, 0);
    transform: skew(0.58deg);
  }
}

@keyframes glitch-anim2 {
  0% {
    clip: rect(86px, 9999px, 4px, 0);
    transform: skew(0.07deg);
  }
  5% {
    clip: rect(35px, 9999px, 64px, 0);
    transform: skew(0.29deg);
  }
  10% {
    clip: rect(52px, 9999px, 74px, 0);
    transform: skew(0.46deg);
  }
  15% {
    clip: rect(66px, 9999px, 10px, 0);
    transform: skew(0.84deg);
  }
  20% {
    clip: rect(23px, 9999px, 92px, 0);
    transform: skew(0.37deg);
  }
  25% {
    clip: rect(46px, 9999px, 16px, 0);
    transform: skew(0.69deg);
  }
  30% {
    clip: rect(72px, 9999px, 81px, 0);
    transform: skew(0.12deg);
  }
  35% {
    clip: rect(7px, 9999px, 45px, 0);
    transform: skew(0.53deg);
  }
  40% {
    clip: rect(94px, 9999px, 39px, 0);
    transform: skew(0.76deg);
  }
  45% {
    clip: rect(18px, 9999px, 97px, 0);
    transform: skew(0.41deg);
  }
  50% {
    clip: rect(58px, 9999px, 27px, 0);
    transform: skew(0.62deg);
  }
  55% {
    clip: rect(83px, 9999px, 3px, 0);
    transform: skew(0.23deg);
  }
  60% {
    clip: rect(38px, 9999px, 87px, 0);
    transform: skew(0.57deg);
  }
  65% {
    clip: rect(11px, 9999px, 53px, 0);
    transform: skew(0.31deg);
  }
  70% {
    clip: rect(96px, 9999px, 8px, 0);
    transform: skew(0.74deg);
  }
  75% {
    clip: rect(29px, 9999px, 62px, 0);
    transform: skew(0.15deg);
  }
  80% {
    clip: rect(77px, 9999px, 19px, 0);
    transform: skew(0.43deg);
  }
  85% {
    clip: rect(2px, 9999px, 88px, 0);
    transform: skew(0.68deg);
  }
  90% {
    clip: rect(49px, 9999px, 31px, 0);
    transform: skew(0.09deg);
  }
  95% {
    clip: rect(70px, 9999px, 36px, 0);
    transform: skew(0.51deg);
  }
  100% {
    clip: rect(13px, 9999px, 82px, 0);
    transform: skew(0.34deg);
  }
}

@keyframes glitch-skew {
  0% {
    transform: skew(1deg);
  }
  10% {
    transform: skew(-4deg);
  }
  20% {
    transform: skew(2deg);
  }
  30% {
    transform: skew(-3deg);
  }
  40% {
    transform: skew(3deg);
  }
  50% {
    transform: skew(-1deg);
  }
  60% {
    transform: skew(5deg);
  }
  70% {
    transform: skew(-2deg);
  }
  80% {
    transform: skew(4deg);
  }
  90% {
    transform: skew(-5deg);
  }
  100% {
    transform: skew(0deg);
  }
}

.intro {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background: url('/src/assets/images/intro-bg.gif') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &-content {
    position: relative;
    z-index: 1;
    padding: 0 20px;
  }

  h5 {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  h1 {
    color: var(--text-color);
    font-size: 4rem;
    font-weight: 700;
    margin-top: 0;

    .gradient-accent {
      display: inline-block;
      position: relative;
      color: #ffffff; // Fallback color
      background: linear-gradient(
        90deg, 
        #ffe66b, 
          #ff8307, 
          #ffaa18,
          #18ffb2, 
          #003d87
      );
      background-size: 300% 300%;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: gradientFlow 8s ease infinite;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 100%;
        height: 4px;
        background: linear-gradient(
          90deg, 
          #ffe66b, 
          #ff8307, 
          #ffaa18,
          #18ffb2, 
          #003d87
        );
        background-size: 300% 300%;
        animation: 
          gradientFlow 8s ease infinite,
          underline 2s ease-in-out infinite alternate;
      }
    }
  }
  @keyframes underline {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }

  @keyframes gradientFlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .intro-position {
    font-size: 1.5rem;
    margin-bottom: 2rem;

    .role-text {
      display: inline-block;
      margin: 0 10px;
      opacity: 0;
      transform: translateY(20px);
      animation: fadeInUp 0.6s forwards;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &::after {
        content: '|';
        margin-left: 10px;
        opacity: 0.5;
      }

      &:last-child::after {
        content: none;
      }
    }
  }

  .button {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: transparent;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 700;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background-color: var(--primary-color);
      transition: all 0.3s ease;
      z-index: -1;
    }

    &:hover {
      color: var(--secondary-color);

      &::before {
        left: 0;
      }
    }
  }

  .scroll-down {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: var(--text-color);
    transition: all 0.3s ease;

    &:hover {
      color: var(--primary-color);
    }

    .scroll-text {
      margin-bottom: 0.5rem;
    }

    i {
      font-size: 1.5rem;
      animation: bounce 2s infinite;
    }
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-10px); }
  60% { transform: translateY(-5px); }
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--primary-color);
  font-size: 2.5rem;
  position: relative;
  
  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: var(--primary-color);
    margin: 0.5rem auto 0;
  }
}

.skills, .recent-work {
  padding: 4rem 0;
  position: relative;
  z-index: 1;
  background: transparent;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Ensure 5 items fit in one line */
  gap: 1rem; /* Reduce gap between items */

  .skill-item {
    text-align: center;
    padding: 1.5rem; /* Slightly reduce padding to fit better */
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
      opacity: 0;
      transform: rotate(45deg);
      transition: all 0.3s ease;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      &::before {
        opacity: 1;
        transform: rotate(45deg) scale(1.5);
      }
    }

    .skill-icon {
      font-size: 2.5rem; /* Slightly reduce icon size */
      color: var(--primary-color);
      margin-bottom: 0.75rem; /* Adjust margin for tighter spacing */
      transition: all 0.3s ease;
    }

    &:hover .skill-icon {
      transform: scale(1.2);
    }

    h3 {
      margin-bottom: 0.75rem; /* Adjust margin */
      font-size: 1.4rem; /* Slightly reduce font size */
    }

    p {
      font-size: 0.85rem; /* Adjust text size for better fit */
      line-height: 1.4;
    }
  }
}


.work-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;

  .work-item {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    }

    .work-image {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;


      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
        transition: transform 0.3s ease;
      }
    }

    &:hover .work-image img {
      transform: scale(1.05);
    }

    .work-info {
      padding: 1.5rem;
      background-color: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);

      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        color: var(--primary-color);
      }

      p {
        font-size: 0.9rem;
        margin-bottom: 1rem;
        line-height: 1.5;
        color: var(--text-color);
      }

      .learn-more {
        display: inline-block;
        padding: 0.5rem 1rem;
        background-color: var(--primary-color);
        color: var(--secondary-color);
        text-decoration: none;
        border-radius: 5px;
        transition: all 0.3s ease;

        &:hover {
          filter: brightness(90%);
          transform: translateX(5px);
        }
      }
    }
  }
}

.cta {
  text-align: center;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  position: relative;
  overflow: hidden;
  padding: 0.5rem 0; // Reduced padding to decrease height

  .container {
    position: relative;
    z-index: 2;
  }

  h2 {
    color: var(--secondary-color);
  }

  p {
    max-width: 600px;
    margin: 0 auto 2rem;
    font-size: 1.1rem;
  }

  .button {
    display: inline-block;
    padding: 1rem 2rem;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 300%;
      height: 300%;
      background-color: rgba(255, 255, 255, 0.1);
      transition: all 0.5s ease;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      z-index: -1;
    }

    &:hover {
      filter: brightness(110%);
      transform: translateY(-3px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    }
  }

  
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .intro {
    h1 {
      font-size: 3rem;
    }

    .intro-position {
      font-size: 1.2rem;
    }
  }

  .skills-grid, .work-grid {
    grid-template-columns: 1fr;
  }

  .section-title {
    font-size: 2rem;
  }

  .cta {
    p {
      font-size: 1rem;
    }
  }
}

@media (max-width: 480px) {
  .intro {
    h1 {
      font-size: 2.5rem;
    }

    .intro-position {
      font-size: 1rem;
    }
  }

  .section-title {
    font-size: 1.8rem;
  }

  .skill-item, .work-item {
    padding: 1.5rem;
  }
}

.now-playing {
  padding: 4rem 0;
  background-color: var(--secondary-color);
  position: relative;
  overflow: hidden;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    text-align: center;
  }

  .section-title {
    color: var(--primary-color);
  }

  p {
    color: var(--text-color);
    margin-bottom: 2rem;
  }

  .now-playing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
      border-radius: 10px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }

  }
}
</style>