<!-- src/components/EnhancedParticleSystem.vue -->
<template>
  <div class="particle-system">
    <div v-for="n in 50" :key="`particle-${n}`" class="particle" :style="particleStyle()"></div>
    <div v-for="n in 3" :key="`star-${n}`" class="shooting-star" :style="shootingStarStyle()"></div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'EnhancedParticleSystem',
  setup() {
    const intervalId = ref(null);

    const particleStyle = () => {
      const size = Math.random() * 5 + 1 + 'px';
      return {
        left: Math.random() * 100 + '%',
        top: Math.random() * 100 + '%',
        width: size,
        height: size,
        animationDuration: (Math.random() * 4 + 2) + 's',
        animationDelay: (Math.random() * 2) + 's'
      };
    };

    const shootingStarStyle = () => {
      return {
        left: Math.random() * 100 + '%',
        top: Math.random() * 50 + '%',
        animationDuration: (Math.random() * 3 + 2) + 's',
        animationDelay: (Math.random() * 15) + 's'
      };
    };

    const triggerShootingStar = () => {
      const stars = document.querySelectorAll('.shooting-star');
      const randomStar = stars[Math.floor(Math.random() * stars.length)];
      randomStar.style.animationName = 'none';
      randomStar.offsetHeight; // Trigger reflow
      randomStar.style.animationName = '';
    };

    onMounted(() => {
      intervalId.value = setInterval(triggerShootingStar, 5000);
    });

    onUnmounted(() => {
      clearInterval(intervalId.value);
    });

    return {
      particleStyle,
      shootingStarStyle
    };
  }
}
</script>

<style scoped>
.particle-system {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.particle {
  position: absolute;
  background-color: rgba(104, 76, 1, 0.818);
  border-radius: 50%;
  animation: funkyFloat 6s infinite ease-in-out;
}

@keyframes funkyFloat {
  0%, 100% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(10px, -15px) rotate(45deg); }
  50% { transform: translate(0, -30px) rotate(90deg); }
  75% { transform: translate(-10px, -15px) rotate(45deg); }
}

.shooting-star {
  position: absolute;
  width: 50px;
  height: 1px;
  background: linear-gradient(90deg, rgba(104, 76, 1, 0.818), transparent);
  animation: shoot 3s ease-in-out infinite;
}

@keyframes shoot {
  0% {
    transform: translateX(-100%) translateY(0) rotate(-45deg);
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateX(200%) translateY(200%) rotate(-45deg);
    opacity: 0;
  }
}
</style>