<!-- src/components/EnhancedFooterComponent.vue -->
<template>
  <footer class="footer">
    <div class="footer-wave">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
    </div>
    <div class="footer-content">
      <div class="footer-inner">
        <div class="footer-social">
          <a v-for="(link, index) in socialLinks" 
             :key="link.url" 
             :href="link.url" 
             target="_blank" 
             rel="noopener noreferrer"
             :style="{ '--delay': `${index * 0.1}s` }"
             class="social-link">
            <i :class="link.icon"></i>
            <span class="tooltip">{{ link.name }}</span>
          </a>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="animated-bottom-line"></div>
        <div class="copyright">
          <p>&copy; {{ currentYear }} <a href="http://www.kohulanr.com/">kohulanr.com</a></p>
        </div>
      </div>
    </div>
    <div class="footer-particles" ref="particlesContainer"></div>
    <div class="animated-line"></div>
    <div class="footer-page-top" @click="scrollToTop" :class="{ visible: isScrolled }">
      <i class="fas fa-chevron-up"></i>
    </div>
  </footer>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'EnhancedFooterComponent',
  setup() {
    const currentYear = ref(new Date().getFullYear());
    const isScrolled = ref(false);
    const particlesContainer = ref(null);
    
    const socialLinks = [
      { name: 'LinkedIn', url: 'https://www.linkedin.com/in/kohulanrajan/', icon: 'fab fa-linkedin' },
      { name: 'GitHub', url: 'https://github.com/Kohulan', icon: 'fab fa-github' },
      { name: 'X', url: 'https://x.com/KohulanRajan', icon: 'fab fa-x-twitter' },
      { name: 'Instagram', url: 'https://www.instagram.com/kohulanr/', icon: 'fab fa-instagram' },
      { name: 'Facebook', url: 'https://www.facebook.com/Kohulan.RPhotography', icon: 'fab fa-facebook' },
      { name: 'ORCID', url: 'http://orcid.org/0000-0003-1066-7792', icon: 'ai ai-orcid' },
      { name: 'Google Scholar', url: 'https://scholar.google.com/citations?user=-1Kqb3IAAAAJ&hl=en', icon: 'ai ai-google-scholar' },
      { name: 'ResearchGate', url: 'https://www.researchgate.net/profile/Kohulan-Rajan', icon: 'ai ai-researchgate' },
    ];

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 300;
    };

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const createParticles = () => {
      const particlesCount = 50;
      const colors = ['#DCFF46', '#FFFFFF', '#5CFFE7'];

      for (let i = 0; i < particlesCount; i++) {
        const particle = document.createElement('div');
        particle.classList.add('particle');
        particle.style.setProperty('--x', `${Math.random() * 100}%`);
        particle.style.setProperty('--y', `${Math.random() * 100}%`);
        particle.style.setProperty('--size', `${Math.random() * 2 + 1}px`);
        particle.style.setProperty('--color', colors[Math.floor(Math.random() * colors.length)]);
        particlesContainer.value.appendChild(particle);
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      createParticles();
    });

    onUnmounted(() => window.removeEventListener('scroll', handleScroll));

    return { currentYear, socialLinks, isScrolled, particlesContainer, scrollToTop };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  --primary-color: #DCFF46;
  --secondary-color: #1A1A1A;
  --text-color: #FFFFFF;
  --primary-color-light: rgba(220, 255, 70, 0.8);
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 6rem 0 2rem;
  position: relative;
  font-size: 0.9rem;
  overflow: hidden;

  &-wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);

    svg {
      position: relative;
      display: block;
      width: calc(300% + 1.3px);
      height: 150px;
      animation: wave 15s linear infinite;

      .shape-fill {
        fill: var(--secondary-color);
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;
    z-index: 2;
  }

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }

  &-social {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;

    .social-link {
      color: var(--text-color);
      font-size: 1.5rem;
      transition: all 0.3s ease;
      opacity: 0;
      transform: translateY(20px);
      animation: fadeInUp 0.5s forwards;
      animation-delay: var(--delay);
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--primary-color);
        bottom: -5px;
        left: 0;
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }

      &:hover {
        color: var(--primary-color);
        transform: translateY(-5px);

        &::after {
          transform: scaleX(1);
        }

        .tooltip {
          opacity: 1;
          visibility: visible;
          transform: translateY(-10px);
        }
      }

      .tooltip {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--primary-color);
        color: var(--secondary-color);
        padding: 0.5rem 1rem;
        border-radius: 4px;
        font-size: 0.8rem;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        white-space: nowrap;

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-width: 5px;
          border-style: solid;
          border-color: var(--primary-color) transparent transparent transparent;
        }
      }
    }
  }

  &-bottom {
    text-align: center;
    padding-top: 2rem;
    position: relative;
  }

  .animated-bottom-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, transparent, var(--primary-color), transparent);
    background-size: 200% 100%;
    animation: shimmer 3s infinite;
  }

  .copyright {
    font-size: 1rem;
    position: relative;
    z-index: 1;
    
    a {
      color: var(--primary-color);
      text-decoration: none;
      position: relative;
      transition: color 0.3s ease;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -2px;
        left: 0;
        background-color: var(--primary-color);
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s ease;
      }

      &:hover {
        color: var(--primary-color-light);

        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }

  &-page-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

    &.visible {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &:hover {
      transform: translateY(-5px) rotate(360deg);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      background-color: var(--primary-color-light);
    }

    i {
      font-size: 1.2rem;
      transition: transform 0.3s ease;
    }
  }

  &-particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }

  .animated-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, var(--primary-color), var(--text-color), var(--primary-color));
    background-size: 200% 100%;
    animation: movingLine 15s linear infinite;
  }
}

.particle {
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background-color: var(--color);
  border-radius: 50%;
  animation: float 20s infinite;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(180deg);
  }
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes movingLine {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 5rem 0 2rem;

    &-social {
      gap: 1rem;

      a {
        font-size: 1.3rem;
      }
    }

    &-page-top {
      bottom: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
    }
  }
}
</style>