<!-- src/components/EnhancedSkillBar.vue -->
<template>
  <div class="skill-bar" ref="skillBarRef">
    <div class="skill-label">
      <strong>{{ skill }}</strong>
      <span class="percentage" :class="{ 'animate': isAnimating }">{{ animatedPercentage }}%</span>
    </div>
    <div class="progress-container">
      <div class="progress-background"></div>
      <div class="progress" :style="{ width: `${animatedPercentage}%` }">
        <div class="progress-glow"></div>
      </div>
      <div class="particles-container">
        <div v-for="n in 20" :key="n" class="particle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'EnhancedSkillBar',
  props: {
    skill: String,
    percentage: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100
    }
  },
  setup(props) {
    const skillBarRef = ref(null);
    const isAnimating = ref(false);
    const animatedPercentage = ref(0);
    const hasAnimated = ref(false);
    let observer = null;

    const startAnimation = () => {
      if (hasAnimated.value) return;
      isAnimating.value = true;
      animateProgress();
    };

    const animateProgress = () => {
      const duration = 1500;
      const start = performance.now();
      const animate = (time) => {
        const timeFraction = (time - start) / duration;
        if (timeFraction > 1) {
          animatedPercentage.value = props.percentage;
          hasAnimated.value = true;
          return;
        }
        const progress = easeOutCubic(timeFraction);
        animatedPercentage.value = Math.round(progress * props.percentage);
        requestAnimationFrame(animate);
      };
      requestAnimationFrame(animate);
    };

    const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);

    onMounted(() => {
      observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          startAnimation();
          observer.disconnect();
        }
      }, { threshold: 0.5 });

      if (skillBarRef.value) {
        observer.observe(skillBarRef.value);
      }
    });

    onUnmounted(() => {
      if (observer) {
        observer.disconnect();
      }
    });

    return {
      skillBarRef,
      isAnimating,
      animatedPercentage
    };
  }
};
</script>

<style lang="scss" scoped>
.skill-bar {
  margin-bottom: 2rem;
  --primary-color: #DCFF46;
  --secondary-color: #1A1A1A;
  --text-color: #FFFFFF;
  --glow-color: rgba(220, 255, 70, 0.6);

  .skill-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    color: var(--text-color);
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;

    .percentage {
      font-weight: bold;
      transition: all 0.3s ease;

      &.animate {
        color: var(--primary-color);
        text-shadow: 0 0 10px var(--glow-color);
      }
    }
  }

  .progress-container {
    position: relative;
    height: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }

  .progress-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1) 10px,
      rgba(255, 255, 255, 0.2) 10px,
      rgba(255, 255, 255, 0.2) 20px
    );
    animation: moveStripes 20s linear infinite;
  }

  .progress {
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 10px;
    transition: width 1.5s cubic-bezier(0.22, 1, 0.36, 1);
    position: relative;
    overflow: hidden;

    .progress-glow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, var(--glow-color), transparent);
      animation: glow 2s infinite;
    }
  }

  .particles-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .particle {
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: var(--primary-color);
    border-radius: 50%;
    opacity: 0;
    animation: particleAnimation 2s ease-out infinite;

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        left: random(100) * 1%;
        animation-delay: random(2000) * 1ms;
      }
    }
  }
}

@keyframes moveStripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 0;
  }
}

@keyframes glow {
  0%, 100% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
}

@keyframes particleAnimation {
  0% {
    transform: translateY(0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px) scale(1);
    opacity: 0;
  }
}
</style>