<!-- src/views/ResumeView.vue -->
<template>
  <section id="resume" class="resume">
    <ParallaxBackground image="/images/resume-bg.jpg" :speed="0.5" />
    <div class="container">
      <div class="row section-intro" v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }">
        <div class="col-twelve">
          <h5>Work & Education</h5>
          <SectionHeader title="More of my credentials." />
          <p class="lead">In my lifetime, I have been fortunate enough to live, work and study in multiple countries. Each of them has shaped me into the person I am today.</p>
        </div>
      </div>

      <div class="row resume-timeline">
        <div class="col-twelve resume-header" v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }">
          <h2><i class="fas fa-flask"></i> Scientific Projects</h2>
        </div>

        <div class="col-twelve">
          <div class="timeline-wrap">
            <TimelineItem 
              v-for="(project, index) in scientificProjects"
              :key="index"
              :title="project.title"
              :date="project.date"
              :subtitle="project.subtitle"
              :description="project.description"
              :icon="project.icon"
              v-motion
              :initial="{ opacity: 0, x: -50 }"
              :enter="{ opacity: 1, x: 0, transition: { delay: index * 100 } }"
            />
          </div>
        </div>
      </div>

      <div class="row resume-timeline">
        <div class="col-twelve resume-header" v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }">
          <h2><i class="fas fa-graduation-cap"></i> Education</h2>
        </div>

        <div class="col-twelve">
          <div class="timeline-wrap">
            <TimelineItem 
              v-for="(education, index) in educationHistory"
              :key="index"
              :title="education.title"
              :date="education.date"
              :subtitle="education.subtitle"
              :description="education.description"
              :icon="education.icon"
              v-motion
              :initial="{ opacity: 0, x: 50 }"
              :enter="{ opacity: 1, x: 0, transition: { delay: index * 100 } }"
            />
          </div>
        </div>
      </div>

      <div class="row skill-bars" v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }">
        <div class="col-twelve">
          <h2><i class="fas fa-code"></i> Professional Skills</h2>
          <SkillBar 
            v-for="(skill, index) in skills"
            :key="skill.name"
            :skill="skill.name"
            :percentage="skill.percentage"
            :icon="skill.icon"
            v-motion
            :initial="{ opacity: 0, x: -50 }"
            :enter="{ opacity: 1, x: 0, transition: { delay: index * 100 } }"
          />
        </div>
      </div>

      <div class="row button-section" v-motion :initial="{ opacity: 0, y: 50 }" :enter="{ opacity: 1, y: 0 }">
        <div class="col-twelve">
          <a href="#contact" class="button stroke smoothscroll">Contact Me</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import SectionHeader from '@/components/SectionHeader.vue';
import TimelineItem from '@/components/TimelineItem.vue';
import SkillBar from '@/components/SkillBar.vue';
import ParallaxBackground from '@/components/ParallaxBackground.vue';

export default {
  name: 'ResumeView',
  components: {
    SectionHeader,
    TimelineItem,
    SkillBar,
    ParallaxBackground
  },
  setup() {
    const scientificProjects = ref([
      {
        title: "PhD Projects",
        date: "July 2017 - May 2021",
        subtitle: "D.A.R.L.I.N.G - Deep leARning for chemicaL Information processinG",
        description: "Vast quantities of scientific information are hidden in primary scientific publications and not available as curated data in scientific databases. Making such information publicly available to support open science and open innovation is a challenge that has to be solved. In this dissertation, state-of-the-art deep learning models for optical chemical structure recognition and chemical information processing have been implemented to rediscover this information and retrieve it automatically.",
        icon: "fas fa-file-code"
      },
      {
        title: "STOUT (Smiles TO iUpac Translator)",
        date: "August 2020 - today",
        description: "STOUT: SMILES TO IUPAC Translator is built using the same concept as a Neural Machine Translation(NMT). STOUT is initially trained on a subset downloaded from PubChem containing 30 Million SMILES and 60 Million SMILES, which got converted into SELFIES using the SELFIES package. The same set of SMILES also was converted into IUPAC names using ChemAxon 'molconvert', a command-line program in Marvin Suite 20.15 from ChemAxon. Later the textual data was converted into TFRecords(Binary files) for training on Tensor Processing Units(TPUs).",
        icon: "fas fa-language"
      },
      {
        title: "MSC (Molecule Set Comparator)",
        date: "December 2019 - June 2020",
        description: "Molecule Set Comparator (MSC) is designed as an application that enables a user to do a versatile and fast comparison of large molecule sets with a unique inter-set, molecule-to-molecule comparison, for the original set and a predicted set of molecules obtained by machine learning approaches. The molecule-to-molecule comparison is based on chemical descriptors, which are included in the Chemistry Development Kit (CDK), such as Tanimoto similarities, atom/bond/ring counts, and physicochemical properties like logP. The results are presented graphically and summarized by interactive histograms that can be exported in publication quality.",
        icon: "fas fa-atom"
      },
      {
        title: "Collaborative Projects",
        date: "July 2020 - today",
        subtitle: "COCONUT COlleCtion of Open NatUral producTs",
        description: "I was part of the data curation work and designed the logo for the project.",
        icon: "fas fa-database"
      },
      {
        title: "Masters' Thesis",
        date: "December 2016 - May 2017",
        subtitle: "A rule-based method for systematic conformer generation",
        description: "A Java based tool to explore and generate the conformers of a bioactive molecule systematically.",
        icon: "fab fa-java"
      }
    ]);

    const educationHistory = ref([
      {
        title: "Ph.D. Thesis",
        date: "July 2017 - May 2021",
        subtitle: "Friedrich-Schiller-Universität, Jena, Germany.",
        description: "In 2021 I completed and defended my Ph.D with a 'Summa cum laude' grade.",
        icon: "fas fa-university"
      },
      {
        title: "Masters Degree",
        date: "July 2015 - June 2017",
        subtitle: "Savithribai Phule Pune University, Pune, India.",
        description: "I completed my Master's degree in Bioinformatics, in Pune, India. For my Masters' thesis, I worked primarily in cheminformatics.",
        icon: "fas fa-graduation-cap"
      },
      {
        title: "Bachelor Degree",
        date: "September 2011 - November 2014",
        subtitle: "Bangalore University, Bangalore, India",
        description: "I completed my Bachelor of Science in Biotechnology, in Bangalore, India.",
        icon: "fas fa-user-graduate"
      }
    ]);

    const skills = ref([
      { name: "Python", percentage: "90", icon: "fab fa-python" },
      { name: "Java", percentage: "85", icon: "fab fa-java" },
      { name: "R", percentage: "85", icon: "fas fa-calculator" },
      { name: "Shell Scripting", percentage: "85", icon: "fas fa-terminal" },
      { name: "Photography", percentage: "90", icon: "fas fa-camera" },
      { name: "Photoshop & Lightroom", percentage: "85", icon: "fas fa-paint-brush" },
      { name: "After effects and Premier", percentage: "80", icon: "fas fa-film" },
      { name: "Illustrator", percentage: "75", icon: "fas fa-pencil-alt" }
    ]);

    return {
      scientificProjects,
      educationHistory,
      skills,
    };
  }
};
</script>

<style lang="scss" scoped>
.resume {
  position: relative;
  background-color: var(--background-color);
  padding: 8rem 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color), var(--primary-color));
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }

  .section-intro {
    text-align: center;
    margin-bottom: 6rem;

    h5 {
      color: var(--primary-color);
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .lead {
      color: var(--text-color);
      opacity: 0.8;
      max-width: 800px;
      margin: 2rem auto 0;
      font-size: 1.1rem;
      line-height: 1.8;
    }
  }

  .resume-header {
    text-align: center;
    margin-bottom: 4rem;

    h2 {
      color: var(--primary-color);
      font-size: 2.5rem;
      position: relative;
      display: inline-block;

      i {
        margin-right: 10px;
        font-size: 2rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 50px;
        height: 2px;
        background-color: var(--primary-color);
        transform: translateX(-50%);
      }
    }
  }

  .timeline-wrap {
    position: relative;
    padding-left: 3rem;

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .skill-bars {
    margin-top: 6rem;

    h2 {
      color: var(--primary-color);
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 3rem;
      position: relative;

      i {
        margin-right: 10px;
        font-size: 2rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 50px;
        height: 2px;
        background-color: var(--primary-color);
        transform: translateX(-50%);
      }
    }
  }

  .button-section {
    text-align: center;
    margin-top: 6rem;

    .button {
      display: inline-block;
      background-color: transparent;
      color: var(--primary-color);
      border: 2px solid var(--primary-color);
      font-size: 1.3rem;
      padding: 1rem 3rem;
      text-decoration: none;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        transition: all 0.3s ease;
        z-index: -1;
      }

      &:hover {
        color: var(--secondary-color);

        &::before {
          left: 0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 6rem 0;

    .section-intro {
      h5 {
        font-size: 1.2rem;
      }

      h1 {
        font-size: 2.5rem;
      }
    }

    .resume-header {
      h2 {
        font-size: 2rem;
      }
    }

    .timeline-wrap {
      padding-left: 2rem;
    }

    .button-section {
      .button {
        font-size: 1.1rem;
        padding: 0.8rem 2rem;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 100%);
    z-index: -1;
  }
}
</style>