<!-- src/views/EnhancedContactView.vue -->
<template>
  <section id="contact" class="contact">
    <div class="contact-background">
      <div class="animated-bg"></div>
    </div>
    <div class="row section-intro">
      <div class="col-twelve">
        <SectionHeader title="Contact" subtitle="I'd Love To Hear From You." />
        <p class="lead" v-motion-slide-visible-once-bottom>
          I would love to hear from you, so please feel free to contact me directly or simply follow me on my GitHub and social media accounts.
        </p>
      </div>
    </div>

    <div class="row contact-content">
      <div class="col-twelve tab-full contact-form">
        <h3 v-motion-slide-visible-once-bottom>Send Me A Message</h3>
        <form @submit.prevent="submitForm" novalidate>
          <div class="form-field" v-motion-slide-visible-once-bottom>
            <input type="text" v-model="form.name" placeholder="Name" required>
            <span class="input-focus-effect"></span>
          </div>
          <div class="form-field" v-motion-slide-visible-once-bottom>
            <input type="email" v-model="form.email" placeholder="Email" required>
            <span class="input-focus-effect"></span>
          </div>
          <div class="form-field" v-motion-slide-visible-once-bottom>
            <input type="text" v-model="form.subject" placeholder="Subject">
            <span class="input-focus-effect"></span>
          </div>
          <div class="form-field" v-motion-slide-visible-once-bottom>
            <textarea v-model="form.message" placeholder="Message" required></textarea>
            <span class="input-focus-effect"></span>
          </div>
          <div class="form-field" v-motion-slide-visible-once-bottom>
            <button class="submitform" type="submit" :disabled="isSubmitting">
              <span class="button-text">{{ isSubmitting ? 'Sending...' : 'Submit' }}</span>
              <span class="button-icon"><i class="fas fa-paper-plane"></i></span>
            </button>
          </div>
        </form>

        <transition name="fade">
          <div v-if="formStatus.show" :class="['message', formStatus.type]">
            {{ formStatus.message }}
          </div>
        </transition>
      </div>

      <div class="col-twelve tab-full contact-info">
        <h3 v-motion-slide-visible-once-bottom>Contact Information</h3>
        <div v-for="(info, index) in contactInfo" :key="index" class="contact-info-item" v-motion-slide-visible-once-bottom>
          <div class="icon">
            <i :class="info.icon"></i>
          </div>
          <div class="contact-info-content">
            <h5>{{ info.title }}</h5>
            <p v-html="info.content"></p>
          </div>
        </div>
      </div>

      <div class="col-twelve tab-full map-container" v-motion-slide-visible-once-bottom>
        <h3>Find Me Here</h3>
        <div class="map-wrapper">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.4691473559756!2d11.579716115748923!3d50.93353627954496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6a898c3634fbb%3A0xdf22378d9f537b71!2sLessingstra%C3%9Fe%208%2C%2007743%20Jena!5e0!3m2!1sen!2sde!4v1617200702624!5m2!1sen!2sde" 
            width="100%" 
            height="400" 
            style="border:0;" 
            allowfullscreen="" 
            loading="lazy">
          </iframe>
          <div class="map-overlay"></div>
        </div>
      </div>

      <div class="col-twelve tab-full visitor-map" v-motion-slide-visible-once-bottom>
        <h3>Visitor Map</h3>
        <a href='https://clustrmaps.com/site/1bris' title='Visit tracker' class="visitor-map-link">
          <img :src='visitorMapUrl' alt='Visitor Map' />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, reactive } from 'vue';
import SectionHeader from '@/components/SectionHeader.vue';

export default {
  name: 'EnhancedContactView',
  components: {
    SectionHeader,
  },
  setup() {
    const form = reactive({
      name: '',
      email: '',
      subject: '',
      message: '',
    });

    const isSubmitting = ref(false);
    const formStatus = reactive({
      show: false,
      type: '',
      message: '',
    });

    const contactInfo = [
      {
        icon: 'fas fa-map-marker-alt',
        title: 'Where to find me',
        content: `
          Institute for Inorganic and Analytical Chemistry<br>
          Friedrich Schiller University<br>
          Lessingstraße 8<br>
          07743 Jena<br>
          Germany
        `,
      },
      {
        icon: 'fas fa-envelope',
        title: 'Email Me At',
        content: 'kohulan.rajan@uni-jena.de',
      },
      {
        icon: 'fas fa-phone',
        title: 'Call Me At',
        content: 'Phone: +49 3641 948783',
      },
    ];

    const visitorMapUrl = ref('//clustrmaps.com/map_v2.png?cl=a3a3a3&w=284&t=tt&d=suJkY0nNpXcl4dK224_-Y29Drj0n66b0Q5xih9pCX7g&co=003a63');

    const submitForm = async () => {
      isSubmitting.value = true;
      formStatus.show = false;

      try {
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        // Success
        formStatus.show = true;
        formStatus.type = 'success';
        formStatus.message = 'Your message was sent, thank you!';
        
        // Reset form
        Object.keys(form).forEach(key => form[key] = '');
      } catch (error) {
        // Error
        formStatus.show = true;
        formStatus.type = 'error';
        formStatus.message = 'Oops! There was a problem sending your message.';
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      form,
      isSubmitting,
      formStatus,
      contactInfo,
      submitForm,
      visitorMapUrl,
    };
  },
}
</script>

<style lang="scss" scoped>
.contact {
  --primary-color: #DCFF46;
  --secondary-color: #1A1A1A;
  --text-color: #FFFFFF;
  --background-color: #121212;
  --accent-color: #FF4081;
  
  position: relative;
  background-color: var(--background-color);
  padding: 15rem 0 12rem;
  overflow: hidden;

  &-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;

    .animated-bg {
      position: absolute;
      top: -50%;
      left: -50%;
      right: -50%;
      bottom: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(45deg, var(--secondary-color) 0%, var(--background-color) 100%);
      opacity: 0.8;
      animation: rotate 20s linear infinite;
      
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(circle, transparent 20%, var(--background-color) 80%);
      }
    }
  }

  .section-intro {
    text-align: center;
    margin-bottom: 5rem;

    .lead {
      font-size: 2rem;
      line-height: 1.6;
      color: rgba(255, 255, 255, 0.8);
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease;

      &:hover {
        color: var(--primary-color);
        transform: translateY(-5px);
      }
    }
  }

  .row {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }

  .col-twelve {
    width: 100%;
  }

  .tab-full {
    padding: 0 2rem;
  }

  .contact-content {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 4rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    }
  }

  .contact-form {
    margin-bottom: 4rem;

    h3 {
      color: var(--primary-color);
      font-size: 3rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .form-field {
      position: relative;
      margin-bottom: 4rem;

      input[type="text"],
      input[type="email"],
      textarea {
        width: 100%;
        background: rgba(255, 255, 255, 0.1);
        border: none;
        border-radius: 5px;
        color: rgba(255, 255, 255, 0.9);
        padding: 1.5rem 2rem;
        font-size: 1.6rem;
        line-height: 2;
        transition: all 0.3s ease-in-out;

        &:focus {
          outline: none;
          background: rgba(255, 255, 255, 0.2);
          box-shadow: 0 0 15px rgba(220, 255, 70, 0.3);
        }
      }

      textarea {
        min-height: 20rem;
      }

      .input-focus-effect {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--primary-color);
        transition: width 0.3s ease-in-out;
      }

      input:focus + .input-focus-effect,
      textarea:focus + .input-focus-effect {
        width: 100%;
      }
    }

    .submitform {
      background-color: var(--primary-color);
      color: var(--secondary-color);
      border: none;
      border-radius: 50px;
      padding: 1.5rem 3rem;
      font-size: 1.6rem;
      font-weight: 700;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;

      .button-text {
        position: relative;
        z-index: 1;
        transition: all 0.3s ease-in-out;
      }

      .button-icon {
        position: absolute;
        right: -30px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }

      &:hover:not(:disabled) {
        background-color: darken(#dcff46, 10%);
        padding-right: 4.5rem;
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

        .button-icon {
          right: 1.5rem;
          opacity: 1;
        }
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  .message {
    padding: 1.5rem;
    margin-top: 2rem;
    border-radius: 4px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;

    &.success {
      background-color: rgba(76, 175, 80, 0.1);
      color: #4CAF50;
      animation: pulse 2s infinite;
    }

    &.error {
      background-color: rgba(244, 67, 54, 0.1);
      color: #F44336;
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    }
  }

  .contact-info {
    margin-bottom: 4rem;

    h3 {
      color: var(--primary-color);
      font-size: 3rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .contact-info-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 3rem;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-5px);
      }

      .icon {
        flex-shrink: 0;
        width: 5rem;
        height: 5rem;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
        transition: all 0.3s ease-in-out;

        i {
          font-size: 2.2rem;
          color: var(--primary-color);
          transition: all 0.3s ease-in-out;
        }
      }

      &:hover .icon {
        background-color: var(--primary-color);
        transform: rotate(360deg);

        i {
          color: var(--secondary-color);
        }
      }

      .contact-info-content {
        h5 {
          color: var(--text-color);
          font-size: 2rem;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 1.6rem;
          line-height: 1.7;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }

  .map-container {
    margin-bottom: 4rem;

    h3 {
      color: var(--primary-color);
      font-size: 3rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .map-wrapper {
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.02);
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);

        .map-overlay {
          opacity: 0;
        }
      }

      iframe {
        display: block;
        width: 100%;
        height: 400px;
        border: none;
      }

      .map-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
        transition: opacity 0.3s ease-in-out;
      }}
  }

  .visitor-map {
    text-align: center;
    margin-top: 4rem;

    h3 {
      color: var(--primary-color);
      font-size: 3rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .visitor-map-link {
      display: inline-block;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease-in-out;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          120deg,
          transparent,
          rgba(255, 255, 255, 0.3),
          transparent
        );
        transition: all 0.6s;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);

        &::before {
          left: 100%;
        }
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media screen and (max-width: 768px) {
  .contact {
    padding: 10rem 0 8rem;

    .contact-content {
      padding: 2rem;
    }

    .section-intro .lead {
      font-size: 1.8rem;
    }

    .contact-form h3,
    .contact-info h3,
    .map-container h3,
    .visitor-map h3 {
      font-size: 2.4rem;
    }

    .form-field {
      margin-bottom: 2rem;

      input[type="text"],
      input[type="email"],
      textarea {
        font-size: 1.4rem;
        padding: 1.2rem 1.5rem;
      }
    }

    .submitform {
      font-size: 1.4rem;
      padding: 1.2rem 2.5rem;
    }

    .contact-info-item {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .icon {
        margin-right: 0;
        margin-bottom: 1rem;
      }

      .contact-info-content {
        h5 {
          font-size: 1.8rem;
        }

        p {
          font-size: 1.4rem;
        }
      }
    }

    .map-container .map-wrapper iframe {
      height: 300px;
    }

    .visitor-map-link img {
      max-width: 100%;
    }
  }
}
</style>