<!-- src/views/UltraEnhancedPortfolioView.vue -->
<template>
  <section class="portfolio">
    <ParallaxBackground :image="require('@/assets/images/heroz-bg.jpg')" :speed="0.5" />
    <div class="portfolio-overlay"></div>
    <div class="portfolio-content">
      <div class="portfolio-header">
        <h1 class="glitch" data-text="Portfolio">Portfolio</h1>
        <p class="subtitle">Capturing moments through my lens</p>
      </div>
      <div v-if="loading" class="loading">
        <div class="spinner"></div>
        <p>Curating visual delights...</p>
      </div>
      <div v-else-if="error" class="error">{{ error }}</div>
      <transition-group v-else name="portfolio-grid" tag="div" class="portfolio-grid">
        <div v-for="(post, index) in randomPosts" :key="post.id" class="portfolio-item" :style="{ '--delay': `${index * 0.1}s` }">
          <div class="portfolio-item-inner">
            <img :src="post.media_url" :alt="post.caption" loading="lazy" @click="openLightbox(post)" />
            <div class="portfolio-item-overlay">
              <div class="portfolio-item-content">
                <h3>{{ truncateCaption(post.caption) }}</h3>
                <p class="date">{{ formatDate(post.timestamp) }}</p>
              </div>
            </div>
            <a :href="post.permalink" 
               target="_blank" 
               rel="noopener noreferrer" 
               class="view-button" 
               @click.stop>
              View on Instagram
              <i class="fas fa-external-link-alt"></i>
            </a>
          </div>
        </div>
      </transition-group>
    </div>
    <transition name="fade">
      <div v-if="lightboxOpen" class="lightbox" @click="closeLightbox">
        <div class="lightbox-content" @click.stop>
          <img :src="lightboxImage.media_url" :alt="lightboxImage.caption" />
          <div class="lightbox-caption">
            <p>{{ lightboxImage.caption }}</p>
            <p class="date">{{ formatDate(lightboxImage.timestamp) }}</p>
          </div>
          <button class="lightbox-close" @click="closeLightbox">&times;</button>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import axios from 'axios';
import ParallaxBackground from '@/components/ParallaxBackground.vue';

export default {
  name: 'UltraEnhancedPortfolioView',
  components: {
    ParallaxBackground,
  },
  data() {
    return {
      instagramPosts: [],
      randomPosts: [],
      loading: true,
      error: null,
      isRefreshing: false,
      lightboxOpen: false,
      lightboxImage: null,
    };
  },
  mounted() {
    this.fetchInstagramPosts();
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    async fetchInstagramPosts() {
      const accessToken = 'IGQWROc0kyMEltcVllTWFTdDFVMUt6b1F1RENyZAFN1cnJiTmZAQM2tPWkNubWpZAem95LWl3RjBEOEJnRC1VVnJXU2dlYUNnMDlsQWVESWFudEl6YlYyelloYmZAyUkp5RTVNWXM4cGxCSGI4VGZAoVW1lb1J2VFJyNmsZD';
      const fields = 'id,caption,media_type,media_url,permalink,timestamp';
      const limit = 100;

      try {
        const response = await axios.get(`https://graph.instagram.com/me/media?fields=${fields}&access_token=${accessToken}&limit=${limit}`);
        this.instagramPosts = response.data.data.filter(post => post.media_type === 'IMAGE');
        this.selectRandomPosts();
        this.loading = false;
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
        this.error = 'Failed to load Instagram posts. Please try again later.';
        this.loading = false;
      }
    },
    selectRandomPosts() {
      const shuffled = this.instagramPosts.sort(() => 0.5 - Math.random());
      this.randomPosts = shuffled.slice(0, 48);
    },
    async refreshGallery() {
      this.isRefreshing = true;
      this.error = null;

      try {
        await this.fetchInstagramPosts();
        await new Promise(resolve => setTimeout(resolve, 1000));
      } catch (error) {
        console.error('Error refreshing gallery:', error);
        this.error = 'Failed to refresh the gallery. Please try again later.';
      } finally {
        this.isRefreshing = false;
      }
    },
    truncateCaption(caption) {
      return caption && caption.length > 50 ? caption.substring(0, 50) + '...' : caption;
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    },
    openLightbox(post) {
      this.lightboxImage = post;
      this.lightboxOpen = true;
      document.body.style.overflow = 'hidden';
    },
    closeLightbox() {
      this.lightboxOpen = false;
      document.body.style.overflow = '';
    },
    handleKeyDown(e) {
      if (e.key === 'Escape' && this.lightboxOpen) {
        this.closeLightbox();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.portfolio {
  position: relative;
  min-height: 100vh;
  background-color: var(--background-color, #000);
  color: var(--text-color, #fff);
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
    z-index: 3;
    animation: gradient-shift 5s linear infinite;
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  &-content {
    position: relative;
    z-index: 2;
    max-width: 1600px;
    margin: 0 auto;
    padding: 80px 40px;
  }

  &-header {
    text-align: center;
    margin-bottom: 60px;

    h1 {
      font-size: 4rem;
      margin-bottom: 20px;
      color: var(--primary-color, #00ffff);
      position: relative;
      display: inline-block;
      text-shadow: 3px 3px 0 var(--secondary-color);
    }

    .subtitle {
      font-size: 1.4rem;
      color: #b1b8ac;
      text-transform: uppercase;
      letter-spacing: 2px;
      position: relative;
      display: inline-block;
      animation: float 4s ease-in-out infinite;
      
      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 50px;
        height: 2px;
        background: var(--primary-color);
        transform: translateX(-50%);
      }
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
    perspective: 1000px;
  }

  &-item {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    position: relative;
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 0.5s forwards;
    animation-delay: var(--delay);

    &:hover {
      transform: translateY(-10px) scale(1.03);
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
      opacity: 0;
      transition: opacity 0.5s ease;
      z-index: -1;
    }

    &:hover::after {
      opacity: 0.2;
    }

    &-inner {
      position: relative;
      overflow: hidden;
      aspect-ratio: 1 / 1;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(circle, transparent 50%, rgba(0,0,0,0.5) 150%);
        z-index: 1;
        opacity: 0;
        transition: opacity 0.5s ease;
      }
    }

    &:hover &-inner::before {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
    }

    &:hover img {
      transform: scale(1.1);
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 20px;
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    &:hover &-overlay {
      opacity: 1;
    }

    &-content {
      color: #fff;
      transform: translateY(20px);
      transition: transform 0.5s ease;

      h3 {
        font-size: 1.2rem;
        margin-bottom: 10px;
      }

      .date {
        font-size: 0.9rem;
        opacity: 0.7;
        margin-bottom: 15px;
      }
    }

    &:hover &-content {
      transform: translateY(0);
    }

    .view-button {
      position: absolute;
      bottom: 20px;
      right: 20px;
      display: inline-flex;
      align-items: center;
      padding: 8px 12px;
      background-color: var(--primary-color, #00ffff);
      color: var(--background-color, #000);
      text-decoration: none;
      border-radius: 20px;
      font-size: 0.9rem;
      font-weight: bold;
      transition: all 0.3s ease;
      z-index: 10;
      opacity: 0;
      transform: translateY(10px);

      i {
        margin-left: 6px;
        font-size: 0.8rem;
        transition: transform 0.3s ease;
      }

      &:hover {
        background-color: var(--secondary-color, #ff00ff);
        transform: translateY(-2px);

        i {
          transform: translateX(3px);
        }
      }
    }

    &:hover .view-button {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-footer {
    text-align: center;
    margin-top: 60px;
  }
}

.loading {
  text-align: center;
  padding: 60px;

  .spinner {
    width: 60px;
    height: 60px;
    border: 4px solid rgba(0, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: var(--primary-color, #00ffff);
    animation: spin 1s linear infinite, pulse 2s infinite;
    margin: 0 auto 30px;
  }

  p {
    color: var(--secondary-color, #ff00ff);
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: glow 1.5s ease-in-out infinite alternate;
  }
}

.error {
  text-align: center;
  color: #ff4444;
  font-size: 1.2rem;
  padding: 40px;
  background: rgba(255, 68, 68, 0.1);
  border-radius: 10px;
  margin-top: 40px;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.glitch {
  position: relative;
  animation: glitch-skew 1s infinite linear alternate-reverse;
  
  &::before,
  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    left: 2px;
    text-shadow: -2px 0 #ff00ff;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim 5s infinite linear alternate-reverse;
  }

  &::after {
    left: -2px;
    text-shadow: -2px 0 #00ffff, 2px 2px #ff00ff;
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim2 5s infinite linear alternate-reverse;
  }
}

.refresh-button {
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover:not(:disabled) {
    background-color: var(--secondary-color);
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  i {
    margin-right: 8px;
  }

  .fa-spin {
    animation: fa-spin 2s infinite linear;
  }
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &-content {
    max-width: 90%;
    max-height: 90%;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 30px rgba(0, 255, 255, 0.3);

    img {
      display: block;
      max-width: 100%;
      max-height: calc(90vh - 100px);
      object-fit: contain;
    }
  }

  &-caption {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;

    p {
      margin: 0;
      font-size: 1rem;
    }

    .date {
      font-size: 0.8rem;
      opacity: 0.7;
      margin-top: 5px;
    }
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: rotate(90deg);
    }
  }
}

.portfolio-grid-enter-active,
.portfolio-grid-leave-active {
  transition: all 0.5s;
}
.portfolio-grid-enter-from,
.portfolio-grid-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 255, 255, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 255, 255, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 255, 255, 0);
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px var(--primary-color), 0 0 35px var(--primary-color), 0 0 40px var(--primary-color), 0 0 50px var(--primary-color), 0 0 75px var(--primary-color);
  }
  to {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px var(--primary-color), 0 0 70px var(--primary-color), 0 0 80px var(--primary-color), 0 0 100px var(--primary-color), 0 0 150px var(--primary-color);
  }
}

@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}

@keyframes glitch-anim {
  0% { clip: rect(74px, 9999px, 79px, 0); }
  5% { clip: rect(7px, 9999px, 27px, 0); }
  10% { clip: rect(56px, 9999px, 27px, 0); }
  15% { clip: rect(30px, 9999px, 35px, 0); }
  20% { clip: rect(97px, 9999px, 60px, 0); }
  25% { clip: rect(57px, 9999px, 99px, 0); }
  30% { clip: rect(55px, 9999px, 80px, 0); }
  35% { clip: rect(88px, 9999px, 82px, 0); }
  40% { clip: rect(34px, 9999px, 50px, 0); }
  45% { clip: rect(64px, 9999px, 83px, 0); }
  50% { clip: rect(9px, 9999px, 16px, 0); }
  55% { clip: rect(17px, 9999px, 67px, 0); }
  60% { clip: rect(32px, 9999px, 58px, 0); }
  65% { clip: rect(39px, 9999px, 43px, 0); }
  70% { clip: rect(12px, 9999px, 68px, 0); }
  75% { clip: rect(42px, 9999px, 39px, 0); }
  80% { clip: rect(48px, 9999px, 28px, 0); }
  85% { clip: rect(31px, 9999px, 99px, 0); }
  90% { clip: rect(90px, 9999px, 11px, 0); }
  95% { clip: rect(81px, 9999px, 49px, 0); }
  100% { clip: rect(31px, 9999px, 17px, 0); }
}

@keyframes glitch-anim2 {
  0% { clip: rect(99px, 9999px, 57px, 0); }
  5% { clip: rect(73px, 9999px, 92px, 0); }
  10% { clip: rect(34px, 9999px, 18px, 0); }
  15% { clip: rect(12px, 9999px, 64px, 0); }
  20% { clip: rect(40px, 9999px, 17px, 0); }
  25% { clip: rect(23px, 9999px, 61px, 0); }
  30% { clip: rect(83px, 9999px, 94px, 0); }
  35% { clip: rect(55px, 9999px, 45px, 0); }
  40% { clip: rect(60px, 9999px, 67px, 0); }
  45% { clip: rect(10px, 9999px, 87px, 0); }
  50% { clip: rect(35px, 9999px, 78px, 0); }
  55% { clip: rect(87px, 9999px, 45px, 0); }
  60% { clip: rect(38px, 9999px, 21px, 0); }
  65% { clip: rect(81px, 9999px, 95px, 0); }
  70% { clip: rect(75px, 9999px, 16px, 0); }
  75% { clip: rect(40px, 9999px, 14px, 0); }
  80% { clip: rect(50px, 9999px, 66px, 0); }
  85% { clip: rect(17px, 9999px, 76px, 0); }
  90% { clip: rect(57px, 9999px, 93px, 0); }
  95% { clip: rect(34px, 9999px, 10px, 0); }
  100% { clip: rect(39px, 9999px, 37px, 0); }
}

@keyframes glitch-skew {
  0% { transform: skew(-2deg); }
  100% { transform: skew(2deg); }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

@keyframes gradient-shift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .portfolio {
    &-content {
      padding: 60px 30px;
    }
    
    &-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 30px;
    }
  }
}

@media (max-width: 768px) {
  .portfolio {
    &-header {
      h1 {
        font-size: 3rem;
      }
      
      .subtitle {
        font-size: 1.2rem;
      }
    }
    
    &-content {
      padding: 40px 20px;
    }
    
    &-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 20px;
    }
    
    &-item {
      &-content {
        h3 {
          font-size: 1rem;
        }
        
        .date {
          font-size: 0.8rem;
        }
      }
      
      .view-button {
        padding: 6px 10px;
        font-size: 0.8rem;
      }
    }
  }

  .lightbox-content {
    max-width: 95%;

    img {
      max-height: calc(95vh - 80px);
    }
  }

  .lightbox-caption {
    padding: 15px;

    p {
      font-size: 0.9rem;
    }

    .date {
      font-size: 0.7rem;
    }
  }
}

@media (max-width: 480px) {
  .portfolio {
    &-header {
      h1 {
        font-size: 2.5rem;
      }
      
      .subtitle {
        font-size: 1rem;
      }
    }
    
    &-grid {
      grid-template-columns: 1fr;
    }
  }

  .refresh-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .lightbox-close {
    font-size: 1.5rem;
  }
}
</style>