<!-- src/views/UltraEnhancedPublicationsView.vue -->
<template>
  <section id="publications" class="publications">
    <ParallaxBackground :image="require('@/assets/images/heroz-bg.jpg')" :speed="1.0" />
    <div class="publications-overlay"></div>
    <div class="particle-system"></div>
    <div class="publications-content">
      <SectionHeader title="Publications" subtitle="Explore My Scholarly Works" />
      <div v-if="loading" class="loading">
        <div class="dna-spinner">
          <div v-for="n in 20" :key="n" class="dna-strand"></div>
        </div>
        <p>Gathering scholarly works...</p>
      </div>
      <div v-else-if="error" class="error">
        {{ error }}
      </div>
      <transition-group 
        name="publication-list" 
        tag="ul" 
        class="publications-list"
        v-else
      >
        <li 
          v-for="(pub, index) in publications" 
          :key="pub.paperId" 
          class="publication-item"
          :style="{ '--delay': `${index * 0.1}s` }"
        >
          <div class="publication-content">
            <h3>{{ pub.title }}</h3>
            <p class="authors">{{ formatAuthors(pub.authors) }}</p>
            <p class="journal">{{ pub.venue }} ({{ pub.year }})</p>
            <div class="citation-count">
              <i class="fas fa-quote-right"></i>
              <span>{{ pub.citationCount }}</span>
            </div>
          </div>
          <div class="publication-actions">
            <a :href="pub.url" target="_blank" rel="noopener noreferrer" class="read-more">
              Read Paper
              <span class="arrow">→</span>
            </a>
          </div>
          <div class="publication-shine"></div>
        </li>
      </transition-group>
    </div>
    <div class="total-citations">
      <span class="label">Total Citations:</span>
      <span class="count">{{ totalCitations }}</span>
    </div>
    <div class="floating-bubbles">
      <div v-for="n in 10" :key="n" class="bubble"></div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import SectionHeader from '@/components/SectionHeader.vue';
import ParallaxBackground from '@/components/ParallaxBackground.vue';

export default {
  name: 'UltraEnhancedPublicationsView',
  components: {
    SectionHeader,
    ParallaxBackground,
  },
  setup() {
    const publications = ref([]);
    const loading = ref(true);
    const error = ref(null);

    const fetchPublications = async () => {
      try {
        const authorId = '1989545751'; // Replace with the actual Semantic Scholar Author ID
        const response = await fetch(`https://api.semanticscholar.org/graph/v1/author/${authorId}/papers?fields=title,authors,year,venue,citationCount,url`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch publications');
        }

        const data = await response.json();
        if (data && Array.isArray(data.data)) {
          publications.value = data.data.sort((a, b) => b.year - a.year);
        } else {
          throw new Error('Invalid data structure received from API');
        }
        loading.value = false;
      } catch (err) {
        error.value = `Failed to fetch publications: ${err.message}`;
        loading.value = false;
        console.error('Error in fetchPublications:', err);
      }
    };

    const formatAuthors = (authors) => {
      if (!authors || authors.length === 0) return 'No authors listed';
      return authors.map(author => author.name).join(', ');
    };

    const totalCitations = computed(() => {
      return publications.value.reduce((total, pub) => total + (pub.citationCount || 0), 0);
    });

    onMounted(() => {
      fetchPublications();
      initParticleSystem();
    });

    const initParticleSystem = () => {
      const particleContainer = document.querySelector('.particle-system');
      for (let i = 0; i < 100; i++) {
        const particle = document.createElement('div');
        particle.classList.add('particle');
        particle.style.setProperty('--x', `${Math.random() * 100}vw`);
        particle.style.setProperty('--y', `${Math.random() * 100}vh`);
        particle.style.setProperty('--delay', `${Math.random() * 5}s`);
        particleContainer.appendChild(particle);
      }
    };

    return {
      publications,
      loading,
      error,
      formatAuthors,
      totalCitations,
    };
  },
};
</script>

<style lang="scss" scoped>
.publications {
  --primary-color: #DCFF46;
  --secondary-color: #1A1A1A;
  --text-color: #FFFFFF;
  --accent-color: #615f00;
  --shine-color: rgba(255, 255, 255, 0.3);

  position: relative;
  padding: 8rem 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  perspective: 1000px;

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
    z-index: 1;
  }

  &-content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 4rem;
  }

  .publications-list {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 2rem;
    margin-top: 4rem;
  }

  .publication-item {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0;
    transform: translateY(50px) rotateX(-10deg);
    animation: fadeInUp 0.8s forwards;
    animation-delay: var(--delay);
    position: relative;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);

    &:hover {
      transform: translateY(-10px) rotateX(5deg) scale(1.05);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3), 0 0 20px var(--accent-color);

      .publication-shine {
        transform: translateX(100%) rotate(45deg);
      }

      .read-more {
        background-color: var(--accent-color);
        color: var(--text-color);
        transform: translateY(-3px);
      }
    }

    .publication-content {
      padding: 1.5rem;
      position: relative;
      z-index: 2;
    }

    h3 {
      font-size: 1.2rem;
      margin-bottom: 0.8rem;
      color: var(--primary-color);
      transition: color 0.3s ease;
    }

    .authors {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      color: #ccc;
    }

    .journal {
      font-size: 0.85rem;
      font-style: italic;
      margin-bottom: 0.8rem;
      color: #999;
    }

    .citation-count {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      color: var(--accent-color);

      i {
        margin-right: 0.5rem;
        animation: pulse 2s infinite;
      }
    }

    .publication-actions {
      padding: 1rem 1.5rem;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 2;
    }

    .read-more {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 1rem;
      background-color: transparent;
      color: var(--primary-color);
      text-decoration: none;
      border-radius: 25px;
      transition: all 0.3s ease;
      font-weight: 600;
      border: 2px solid var(--primary-color);
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          120deg,
          transparent,
          var(--accent-color),
          transparent
        );
        transition: all 0.5s;
      }

      &:hover::before {
        left: 100%;
      }

      .arrow {
        display: inline-block;
        margin-left: 5px;
        transition: transform 0.3s ease;
      }

      &:hover .arrow {
        transform: translateX(3px);
      }
    }

    .publication-shine {
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(
        to right,
        transparent,
        var(--shine-color),
        transparent
      );
      transform: translateX(-100%) rotate(45deg);
      transition: transform 0.5s;
    }
  }

  .loading,
  .error {
    text-align: center;
    margin: 3rem 0;
    font-size: 1.2rem;
    color: #fff;
  }

  .dna-spinner {
    width: 60px;
    height: 60px;
    position: relative;
    margin: 0 auto 1.5rem;
    animation: dnaRotate 5s linear infinite;

    .dna-strand {
      position: absolute;
      width: 2px;
      height: 100%;
      background-color: var(--primary-color);
      left: 50%;
      transform-origin: 50% 100%;

      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          transform: rotate(#{$i * 18}deg);
          animation: dnaStretch 1s ease-in-out infinite;
          animation-delay: #{$i * 0.05}s;
        }
      }
    }
  }

  .total-citations {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--primary-color);
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    z-index: 10;
    box-shadow: 0 0 20px var(--accent-color);
    animation: float 3s ease-in-out infinite;

    .label {
      margin-right: 10px;
    }

    .count {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  .particle-system {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }

  .particle {
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: var(--primary-color);
    border-radius: 50%;
    opacity: 0.5;
    animation: particleFloat 20s linear infinite;
    animation-delay: var(--delay);
  }

  .floating-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }

  .bubble {
    position: absolute;
    bottom: -50px;
    background-color: var(--accent-color);
    border-radius: 50%;
    opacity: 0.3;
    animation: bubbleFloat 15s ease-in infinite;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        left: #{$i * 10%};
        width: #{random(30) + 20}px;
        height: #{random(30) + 20}px;
        animation-duration: #{random(5) + 10}s;
        animation-delay: #{random(5)}s;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 30%, rgba(220, 255, 70, 0.1) 0%, transparent 50%),
      radial-gradient(circle at 80% 70%, rgba(255, 64, 129, 0.1) 0%, transparent 50%);
    z-index: 0;
    animation: backgroundPulse 10s ease-in-out infinite alternate;
  }
}

@keyframes fadeInUp {
  from { 
    opacity: 0; 
    transform: translateY(50px) rotateX(-10deg);
  }
  to { 
    opacity: 1; 
    transform: translateY(0) rotateX(0);
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

@keyframes dnaRotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes dnaStretch {
  0%, 100% { height: 100%; }
  50% { height: 50%; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes particleFloat {
  0% { transform: translateY(0) translateX(0); }
  100% { transform: translateY(-100vh) translateX(100vw); }
}

@keyframes bubbleFloat {
  0% { transform: translateY(0) scale(1); opacity: 0.3; }
  100% { transform: translateY(-100vh) scale(2); opacity: 0; }
}

@keyframes backgroundPulse {
  0% { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes shimmer {
  0% { transform: translateX(-100%) rotate(45deg); }
  100% { transform: translateX(100%) rotate(45deg); }
}

@keyframes floatAnimation {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.publication-list-enter-active,
.publication-list-leave-active {
  transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.publication-list-enter-from,
.publication-list-leave-to {
  opacity: 0;
  transform: translateY(30px) rotateX(-10deg);
}

.publication-list-move {
  transition: transform 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media (max-width: 1200px) {
  .publications {
    &-content {
      padding: 0 2rem;
    }
    
    .publications-list {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
}

@media (max-width: 768px) {
  .publications {
    padding: 6rem 0;

    &-content {
      padding: 0 1.5rem;
    }

    .publications-list {
      grid-template-columns: 1fr;
    }

    .publication-item {
      transform: none;
      
      &:hover {
        transform: translateY(-5px) scale(1.02);
      }

      h3 {
        font-size: 1.1rem;
      }
    }

    .total-citations {
      bottom: 10px;
      right: 10px;
      font-size: 0.9rem;

      .count {
        font-size: 1.1rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .publications {
    .publication-item {
      .publication-content {
        padding: 1rem;
      }

      .publication-actions {
        padding: 0.75rem 1rem;
      }

      .read-more {
        font-size: 0.9rem;
        padding: 0.4rem 0.8rem;
      }
    }

    .total-citations {
      font-size: 0.8rem;
      padding: 8px 12px;

      .count {
        font-size: 1rem;
      }
    }
  }
}

// Enhanced loading animation
.dna-spinner {
  .dna-strand {
    &::before, &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: var(--primary-color);
      border-radius: 50%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      top: auto;
      bottom: 0;
    }
  }
}

// Particle system enhancements
.particle-system {
  .particle {
    filter: blur(1px);
    box-shadow: 0 0 5px var(--accent-color);
  }
}

// Floating bubbles enhancements
.floating-bubbles {
  .bubble {
    background: radial-gradient(circle at 30% 30%, var(--accent-color), transparent);
    box-shadow: 0 0 10px var(--accent-color);
  }
}

// Add subtle text animation to the total citations
.total-citations {
  .count {
    display: inline-block;
    animation: countUp 2s ease-out;
  }
}

@keyframes countUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
</style>