import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ResumeView from '../views/ResumeView.vue'
import PublicationsView from '../views/PublicationsView.vue'
import PortfolioView from '../views/PortfolioView.vue'
import ContactView from '../views/ContactView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      title: 'Home - Kohulan Rajan',
      metaTags: [
        {
          name: 'description',
          content: 'Welcome to the portfolio of Kohulan Rajan, a computational chemist and photographer.'
        },
        {
          property: 'og:description',
          content: 'Welcome to the portfolio of Kohulan Rajan, a computational chemist and photographer.'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
    meta: {
      title: 'About - Kohulan Rajan',
      metaTags: [
        {
          name: 'description',
          content: 'Learn more about Kohulan Rajan, his background, and expertise in computational chemistry and photography.'
        }
      ]
    }
  },
  {
    path: '/resume',
    name: 'Resume',
    component: ResumeView,
    meta: {
      title: 'Resume - Kohulan Rajan',
      metaTags: [
        {
          name: 'description',
          content: 'View Kohulan Rajan\'s professional resume, including his education, work experience, and skills.'
        }
      ]
    }
  },
  {
    path: '/publications',
    name: 'Publications',
    component: PublicationsView,
    meta: {
      title: 'Publications - Kohulan Rajan',
      metaTags: [
        {
          name: 'description',
          content: 'Explore Kohulan Rajan\'s scientific publications and research contributions in computational chemistry.'
        }
      ]
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: PortfolioView,
    meta: {
      title: 'Portfolio - Kohulan Rajan',
      metaTags: [
        {
          name: 'description',
          content: 'Browse Kohulan Rajan\'s portfolio showcasing his work in computational chemistry and photography.'
        }
      ]
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView,
    meta: {
      title: 'Contact - Kohulan Rajan',
      metaTags: [
        {
          name: 'description',
          content: 'Get in touch with Kohulan Rajan for collaborations, inquiries, or opportunities.'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router